import Error from "components/Error";
import Process from "components/Process";
import ErrorContext from "context/ErrorContext";
import UserContext from "context/userContext";
import { useContext, useEffect, useState } from "react";
import { find, updateExtraDetails } from "services/collection";
import { handleErrors } from "services/messages";
import styles from "./CardPayments.module.css";
import { Formik, Form, Field, /* ErrorMessage */ } from 'formik';
import { sign } from "services/blockchain";
import { WinterCheckout } from '@usewinter/checkout';
import { useParams } from "react-router-dom";

const CardPayments = () => {

  const { domain, projectKey, blockchain } = useParams();
  const { error , setError } = useContext(ErrorContext);
  const { user} = useContext(UserContext);
  const [collection ,setCollection] =  useState([])
  const [msg, setMsg] = useState({})
  const [showWinter, setShowWinter] = useState(false)

  useEffect(() => {
    const getCollection = async() =>{
      if(user) {
        try {
          setMsg({ process : {
            title: 'Loading your collection',
            message: 'We show your mint type available in  moments'
          }})
          let newCollections = await find(domain,projectKey)
          console.log(newCollections)
          setCollection(newCollections)
          setMsg({})
          setError(null)
        } catch (error) {
          setError(handleErrors(error))
          setMsg({})
        }
      }
    }
    getCollection()
  },[user, domain, projectKey, setError]);

  console.log('select ',projectKey,collection,user,error)
  const handleSubmit = async(values) => {
    console.log('values ::', values)
    let extraDetails = JSON.stringify(values)
    let { signature , message } = await sign(user.wallet,user.provider,"UPDATE_COLLECTION")
    await updateExtraDetails(domain,blockchain,projectKey,extraDetails,message,signature)
  }

  return (
    <>
      {user && collection && !msg.process && !error &&
        <>
            <h1 className={styles.title}>Manage your winter project</h1>
            <div className={styles.sectionCardPayments}>
                <Formik
                initialValues={collection.extraDetails}
                onSubmit={handleSubmit}
                className={styles.sectionconfirm}>
                    {({ errors, touched, values }) => (
                    <Form  className={styles.sectionconfirm}>
                        <div className={styles.price}>Winter id</div>
                        <Field className={styles.sectionconfirmChild} name="winterId" type="number" step="1"/>
                        {errors.winterId && touched.winterId && (
                            <div className={styles.error}>{errors.winterId}</div>
                        )}
                        <div className={styles.price}>Production</div>
                        <Field className={styles.sectionconfirmChild} name="production"  type="checkbox"/>
                        {errors.production && touched.production && (
                            <div className={styles.error}>{errors.production}</div>
                        )}
                        <div className={styles.deploybuttongroup}>
                            <button className={styles.confirmbutton}  type="submit">
                            <div className={styles.confirm}>Confirm</div>
                            </button>
                        </div>
                        <button  type="button" onClick={() => {setShowWinter(true)}}>
                            Show checkout
                        </button>
                        { values && values.winterId &&
                            <WinterCheckout
                            projectId={values.winterId}
                            production={ (values.production) ? true : false}
                            extraMintParams={{ quantity : 1}}
                            showModal={showWinter} 
                            onClose={() => setShowWinter(false)} />

                        }
                    </Form>
                    )}
                </Formik>
            </div>
        </>
      }
      {msg && msg.process && !error &&
        <Process
          title={msg.process.title}
          message={msg.process.message} />
      }
      { error && projectKey &&
        <Error
          message = {error.message}
          error={error.status}
          title = { error.title}
          key = {projectKey}/>
      }
    </>
  )
}

export default CardPayments;