import axios from "axios";

export const createSession = async(domain, user) => {
    return axios.get(process.env.REACT_APP_API+`/user?domain=${domain}&signature=${user.signature}&message=${user.message}`,{
        headers:{
            'X-API-Key' : process.env.REACT_APP_X_API_KEY
        }
    })
    .then(res=>{
        return res.data
    })
}

export const findByWallet = async(domain, blockchain, wallet) => {
    return axios.get(process.env.REACT_APP_API+`/user?domain=${domain}&wallet=${wallet}&blockchain=${blockchain}&limit=15&page=0`,{
        headers:{
            'X-API-Key' : process.env.REACT_APP_X_API_KEY
        }
    })
    .then(res=>{
        return res.data[0]
    })
}

export const findAllByRole = async(domain, role) => {
    return axios.get(process.env.REACT_APP_API+`/user?domain=${domain}&role=${role}&limit=1000&page=0`)
    .then(res=>{
        return res.data
    })
}

export const findWalletsByEmail = async(domain, emails) => {
    let params = ""
    for (const email of emails) {
        params += `&email=${email.email}`
    }
    return axios.get(process.env.REACT_APP_API+`/relay?domain=${domain}${params}`)
    .then(res=>{
        return res.data
    })
}

export const findAllWithAbout = async(domain)  => {
    const users = await findAllByRole(domain,1)
    let owners = []
    for (const user of users) {
        if(user.about && user.about.includes('phone')) {
            let about = JSON.parse(user.about)
            if(about.token_id) {
                owners.push({
                    email : user.email,
                    phone : about.phone,
                    newsLetter : about.accepted_newsletter,
                    nft : about.token_id,
                    id : about.token_id
                })
            }
        }
    }
    return owners
}

export const create =async(domain, blockchain, user) => {
    const data = {
        'domain': domain,
        'blockchain_name': blockchain,
        'homepage':'',
        'username': user.address,
        'email': user.email,
        'about':'',
        'role':100,
        'twitter':'',
        'facebook':'',
        'profile_pic_url':'',
        'telegram':'',
        'banner_url': '',
        'signature': user.signature,
        'message': user.message,
        'action': 'USER_CREATED'
    }
    return axios.post(process.env.REACT_APP_API+`/user?domain=${domain}`,data,{
        headers:{
            'X-API-Key' : process.env.REACT_APP_X_API_KEY
        },
        params : {
            signature : user.signature,
            message : user.message
        }
    })
    .then(res=>res.data)
}


export const createIfNotExists = async(domain, blockchain, wallet, signature, message) => {
    let user = await findByWallet(domain,blockchain,wallet);
    if(!user.registered) {
        let newUser = {
            address : wallet,
            email : wallet+"@"+domain,
            signature : signature,
            message : message
        }
        await create(domain, blockchain, newUser)
    }
    console.log('user', user)
}
