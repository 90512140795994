import { Alert, Backdrop, CircularProgress, Snackbar } from "@mui/material";
import { useState } from "react";
import { getDisplayName } from "./common";
import { createPortal } from "react-dom";

export default function WithLoadingBackdropAndErrorSnack(WrappedComponent) {
  const Component = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    return (
      <>
        <WrappedComponent {...props} setError={setError} setIsLoading={setIsLoading} />
        {createPortal(
          <>
            <Backdrop open={isLoading}>
              <CircularProgress />
            </Backdrop>
            <Snackbar
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              open={error !== null}
              autoHideDuration={6000}
              onClose={() => setError(null)}
            >
              <Alert severity="error" variant='filled' onClose={() => setError(null)}>
                {error}
              </Alert>
            </Snackbar>
          </>,
          document.body
        )}
      </>
    );
  }

  Component.displayName = `WithLoadingBackdropAndErrorSnack(${getDisplayName(WrappedComponent)})`;
  return Component;
}