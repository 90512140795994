import styles from "./EditCollection.module.css";
import { isProjectMinted, update } from "services/collection";
import CreateEditCollection from "components/CreateEditCollectionForm/CreateEditCollectionForm";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { find } from "services/collection";
import Process from "components/Process";

const EditCollection = () => {

  const { projectKey, domain } = useParams();
  const navigate = useNavigate();
  const [ loading, setLoading ] = useState({isLoading: true, fail: false});
  const [ initialValues, setInitialValues ] = useState({
    name: "",
    description: "",
    blockchain: 0,
    thumb: null,
    banner: null,
  });

  useEffect(() => {
    let ignore = false;
    async function getCollection() {
      if(!ignore) {
        const collectionsResult = await find(domain, projectKey);
        setInitialValues(buildInitialValues(collectionsResult));
        setLoading({isLoading: false, fail: false});
      }
    }
    getCollection()
    return () => {
      ignore = true;
    }
  }, [projectKey,domain]);

  const buildInitialValues = (collection) => {
    return {
      name: collection.name,
      thumb: collection.thumb_url,
      description: collection.description,
      banner: collection.banner_url,
      blockchain: collection.blockchain,
    }
  }

  const submit = async (collectionData) => {
    await update(
      domain,
      projectKey,
      collectionData,
      collectionData.message,
      collectionData.signature
    );
    navigate(`/${domain}/home`);
  }

  const submitButton = () => {
    return (
      <button className={styles.updateButton} type="submit">
        <b className={styles.updateButtonText}>Update</b>
      </button>
    );
  }

  const requestSuccess = () => {
    return (!loading.isLoading && !loading.fail);
  }

  const requestInProcess = () => {
    return (loading.isLoading && !loading.fail);
  }

  const requestFailed = () => {
    return (!loading.isLoading && loading.fail);
  }

  return (
    <>
    {requestSuccess() &&
      <CreateEditCollection
        submitButton={ submitButton() }
        manageSubmit={ (collection) => submit(collection) }
        initialValues={ initialValues }
        editBlockchain={ !isProjectMinted(projectKey) }
        />
    }
    {requestInProcess() &&
      <Process
        title={"Loading"} 
        message={"We are ensuring that your project exists"} />
    }
    {requestFailed() &&
      <p>Invalid project</p>
    }
    </>
  );
};

export default EditCollection;