import styles from "./Address.module.css";
import {
    Tooltip,
  } from 'react-tippy';

function Address({ address }) {
    const copyText = async() => {
        await navigator.clipboard.writeText(address);
    }
    return (
        <Tooltip
        title="Copied!"
        position="bottom"
        trigger="click">
            <b className={styles.walletAddress} onClick={ () => copyText() }>
                { address.substring(0,4)}...{ address.substring(address.length - 4)}
            </b>
        </Tooltip>
    )
}

export default Address;