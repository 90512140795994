import ConfirmDeployCard from "components/ConfirmDeployCard";
import SectionPreviewLinksDiv from "components/SectionPreviewLinksDiv";

const MintSell = ({ mintStatus, handlePreview, handleConfirm}) => {
    return (
        <>  { mintStatus  === 0 &&
                <SectionPreviewLinksDiv  handlePreview = {handlePreview} />
            }
            {mintStatus  === 2 &&
                <ConfirmDeployCard handleConfirm={handleConfirm}  handlePreview = {handlePreview}/>
            }
        </>
    )
}

export default MintSell;
