import axios from "axios";
import { getHeadersAuth } from "./app";

const url = process.env.REACT_APP_API+"relay"

export const addToWhitelistMTX = async (domain, blockchain, project_key, emails, amounts) => {
  const body = {
    blockchain: blockchain,
    type: "ONLY_TRANSACTION",
    project_key: project_key,
    params: {
      emails: emails,
      amounts: amounts,
    },
    method: "addToWhitelist",
  };
  const fullEndpoint = `${url}?domain=${domain}&blockchain=${blockchain}&type=ONLY_TRANSACTION`;
  const response = await axios.post(fullEndpoint, body, getHeadersAuth());
  return await response.data;
}