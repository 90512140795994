import { Input } from '@mui/material';
import FormError from 'components/FormError';


const InputFile = ({ setFieldValue, errors,touched,name, title, textAlign }) => {
    return (
        <div style={{width: '100%', textAlign: textAlign}}>
            <label htmlFor={name}  style={{width: '100%'}}>{title.replace(/^\w/, (c) => c.toUpperCase())}</label>
            <Input
                id={name}
                name={name}
                type="file"
                style={{width: '100%'}}
                error={(errors[name] && touched[name])}
                onChange={(e) =>{
                    console.log('e',e)
                    setFieldValue(name, e.currentTarget.files[0])}
                }
            />
            <FormError errors = {errors} touched = {touched} name={name}/>
        </div>
    )
}

export default InputFile;