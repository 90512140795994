import { useState } from "react";
import MintBatchAndReveal from "./MintPanels/MintBatchAndReveal";
import MintBatchAndRevealGasless from "./MintPanels/MintBatchAndRevealGasless";
import MintSell from "./MintPanels/MintSell";
import OnlyMintBatch from "./MintPanels/OnlyMintBatch";
import MintBatchWeeksGasless from "./MintPanels/MintBatchWeeksGasless";
import { MINT_TYPES } from "services/blockchain";
import DeployWithPriceAndPayees from "./MintPanels/DeployWithPriceAndPayees";

const SwitchMintTypePanel = ({ mintType, mintStatus, handlePreview, handleConfirm }) => {
    const [checked, setChecked] = useState((mintStatus === 0) ? false : true);

    const handleCheckboxChange = () => {
      setChecked(!checked);
    };
    return (
        <>
            { ![MINT_TYPES.mintWeeksGasless, MINT_TYPES.mintWithPriceAndPayees].includes(parseInt(mintType)) &&
                <h2 style={{width:'100%', textAlign:'center'}}>
                    <input
                        type="checkbox"
                        checked={checked}
                        disabled={checked}
                        onChange={handleCheckboxChange}/>
                    I have shared my CSVs and my folder to the email : <br /> <b>{process.env.REACT_APP_MINTER_EMAIL}</b>
                </h2>
            }
            { parseInt(mintType) === 1 && checked === true &&
                <MintSell
                mintStatus={mintStatus}
                handlePreview={handlePreview}
                handleConfirm={handleConfirm} />
            }

            { parseInt(mintType) === 2 && checked === true &&
                <OnlyMintBatch
                mintStatus={mintStatus}
                handlePreview={handlePreview}
                handleConfirm={handleConfirm} />
            }

            { parseInt(mintType) === 3 && checked === true && 
                <MintBatchAndReveal
                mintStatus={mintStatus}
                handlePreview={handlePreview}
                handleConfirm={handleConfirm} />
            }
            { parseInt(mintType) === 4 && checked === true && 
                <MintBatchAndRevealGasless
                    mintStatus={mintStatus}
                    handlePreview={handlePreview}
                    handleConfirm={handleConfirm} />
            }
            { parseInt(mintType) === 6 &&
                <MintBatchWeeksGasless
                    mintStatus={mintStatus}
                    handlePreview={handlePreview}
                    handleConfirm={handleConfirm} />
            }
            { parseInt(mintType) === MINT_TYPES.mintWithPriceAndPayees &&
                <DeployWithPriceAndPayees
                    mintStatus={mintStatus}
                    handlePreview={handlePreview}
                    handleConfirm={handleConfirm} />
            }
        </>
    )
}

export default SwitchMintTypePanel;
