import UserContext from "../../context/userContext";
import CollectionCard from "../../components/CollectionCard";
import styles from "./ContainerCollections.module.css";
import React,{ useContext, useEffect, useState } from "react";
import { findAllByCreatorEmpty, findAllWithNFTs } from "services/collection";
import { useNavigate, useParams } from "react-router-dom";
import Button from "components/Button";
import ErrorContext from "context/ErrorContext";
import Process from "components/Process";
import Error from "components/Error";
import { handleErrors } from "services/messages";
import { Grid  } from '@mui/material';
import Box from '@mui/material/Box';

const ContainerCollections = () => {

  const { domain } = useParams();
  const { user} = useContext(UserContext);
  const { error , setError } = useContext(ErrorContext);
  const navigate = useNavigate();
  const [emptyCollections ,setEmptyCollections] =  useState([])
  const [collections ,setCollections] =  useState([])
  const [msg, setMsg] = useState(null)

  useEffect(() => {
    const getCollections = async() =>{
      if(user) {
        try {
          setMsg({ process : {
            title: 'Loading collections',
            message: 'We show your collections in  moments'
          }})
          let newCollections = await findAllWithNFTs(domain,user.wallet)
          let newEmptyCollections = await findAllByCreatorEmpty(domain,user.wallet)
          setCollections(newCollections)
          setEmptyCollections(newEmptyCollections)
          setMsg(null)
        } catch (error) {
          setError(handleErrors(error))
          setMsg(null)
        }
      } else {
        navigate(`/${domain}`);
      }
    }
    getCollections()
  },[user, domain, setError, navigate]);

  const create = () => {
    navigate(`/${domain}/create`);
  }

  if(collections.length === 0 && emptyCollections.length  === 0) {
    return (
      <>
        {!error &&
          <>
            <h1 className={styles.title}>Mint one and list</h1>
            <div className={styles.home}>
              <h5 className={styles.emptyCollections} style={{textAlign:'center'}}>
                Try to create a collection!
              </h5>
              <div style={{ margin: '1px auto', height: '10%'}}>
                <Button text="Create" handler={create}/>
              </div>
            </div>
          </>
        }
        { error &&
          <Error
            message = {error.message}
            error={error.status}
            title = { error.title} />
        }
      </>

    )
  }

  const buildCollectionCards = (collectionList) => {
    return collectionList.map((collection) => {
        return <Grid item xs={12} md={3}  key={ collection.key }>
            <CollectionCard collection={ collection } />
        </Grid>

    });
  }

  return (
    <>
      {user && !msg && !error &&
        <>
          <h1 className={styles.title}>Your collections</h1>
          <Box sx={{ flexGrow: 1 , maxWidth:'1900px'}}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}  l={12}>
                    <Box sx={{ flexGrow: 1 , border:'1px solid'}}>
                        <h5 className={styles.emptyCollections} sx={{ padding : '5px'}}>Empty collections</h5>
                        <Grid container spacing={2}>
                            { emptyCollections &&
                                buildCollectionCards(emptyCollections)
                            }
                            {emptyCollections.length === 0 &&
                                <Grid item xs={12} md={12}>
                                    <h6 className={styles.emptyCollections} sx={{ padding : '2%'}}>
                                        Please create a collection
                                    </h6>
                                </Grid>
                            }
                        </Grid>
                    </Box>
                </Grid>
                { collections.length > 0 &&
                    <Grid item xs={12} md={12} >
                        <Box sx={{ flexGrow: 1 , border:'1px solid'}}>
                            <h5 className={styles.emptyCollections}>Collections with nfts</h5>
                            <Grid container spacing={2}>
                                { collections &&
                                    buildCollectionCards(collections)
                                }
                            </Grid>
                        </Box>
                    </Grid>
                }
            </Grid>
          </Box>


        </>
      }
      {msg && msg.process && !error &&
        <Process
          title={msg.process.title}
          message={msg.process.message} />
      }
      { error &&
        <Error
          message = {error.message}
          error={error.status}
          title = { error.title} />
      }
    </>
  );
};

export default ContainerCollections;
