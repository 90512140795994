import ConfirmDeployWithReservedTokensAndClaimants from "components/ConfirmDeployWithReservedTokensAndClaimants";
import SectionPreviewPriceAndPayees from "components/SectionPreviewPriceAndPayees";

export default function DeployWithPriceAndPayees ({ mintStatus, handlePreview, handleConfirm}) {
    return (
        <>  { mintStatus  === 0 &&
                <SectionPreviewPriceAndPayees  handlePreview = {handlePreview} />
            }
            {mintStatus  === 2 &&
                <ConfirmDeployWithReservedTokensAndClaimants
                    handleConfirm={handleConfirm} 
                    handlePreview = {handlePreview}/>
            }
        </>
    )
}
