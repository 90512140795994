import { TextField } from "@mui/material";

export default function WrappedMaterialTextField ({ field, form: { touched, errors }, ...props })  {
    const keys = field.name.split('.');

    const getTouched = () => {
        let targetValue = touched;
        for (const key of keys) {
            try {
                targetValue = targetValue[key];
            } catch (error) {
                return false;
            }
        }
        return targetValue;
    }

    const getError = () => {
        if (!getTouched()) return "";
        let targetValue = errors;
        for (const key of keys) {
            try {
                targetValue = targetValue[key];
            } catch (error) {
                return "";
            }
        }
        return targetValue;
    }

    return (
        <TextField
            {...field}
            {...props}
            error={(Boolean(getTouched()) && Boolean(getError()))}
            helperText={getError()}
        />
    )
}