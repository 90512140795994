import {  useContext, useState  } from "react";
import styles from "./Topbar.module.css";
import styled from 'styled-components';
import Button from "./Button"
import Address from "./Address"
import { login } from "../services/wallet";
import UserContext from "context/userContext";
import {  useNavigate, useParams } from "react-router-dom";
import { findFirst, sign } from "services/blockchain";
import { createIfNotExists, createSession } from "services/user";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import ErrorContext from "context/ErrorContext";
import { handleErrors } from "services/messages";
import { validateDomain } from "services/app";
import { Stack } from "@mui/material";



const ButtonCreate = styled(Button)`
    .connectWalletButton  {
        width: 100px;
    }
`;

function Topbar() {

    const { domain } = useParams()
    const { user, setUser } = useContext(UserContext);
    const { setError } = useContext(ErrorContext);
    const navigate = useNavigate();
    const [msg, setMsg] = useState(null)

    const openWeb3Auth = async() => {
        try {
            setMsg({ process : {
                title: 'Connecting  wallet',
                message: ''
            }})
            if(!validateDomain(domain)) {
                throw new Error('Invalid app')
            }
            const blockchain = await findFirst(domain,false);
            console.log('blockchain',blockchain)
            const user = await login(blockchain)
            setUser(user)
            let { signature , message } = await sign(user.wallet, user.provider, "SIGN IN USER")
            await createIfNotExists(domain, blockchain.blockchain_name, user.wallet, signature, message)
            let jwt = await createSession(domain, {
                signature : signature,
                message : message
            })
            console.log('jwt: ',jwt)
            localStorage.setItem('jwt', jwt)
            setMsg(null)
            goHome()
        } catch (error) {
            setError(handleErrors(error))
            setMsg(null)
        }
    }

    const goHome = () => {
        setError(null)
        navigate("home");
    }

    const create = () => {
        setError(null)
        navigate("create");
    }

    const goToDeployByABI = () => {
        navigate(`/${domain}/deploy-by-abi/`);
    }

    return (
        <div className={styles.topbar}>
        <div className={styles.navleft}>
          <img
            onClick={ () => { goHome() } }
            className={styles.persealogoIcon}
            alt=""
            src="/PerseaHeader.svg"
          />
            <small style={{ marginLeft: '5px', fontSize:'14px', color:'#FF0000'}}>Proof of concept</small>
        </div>
        <div className={styles.navrigth}>
            { msg && msg.process &&
                <>
                    <small>
                        {msg.process.title}
                    </small>&nbsp;
                    <FontAwesomeIcon icon={faSpinner} size={'1x'} color="#000"/>
                </>

            }
            { !msg && !user &&
                <Button  text="Connect wallet" handler={openWeb3Auth}/>
            }
            { !msg && user &&
                <Stack direction="row" spacing={1}>
                    <Address address={user.wallet} />
                    <Button  text="Deploy with ABI" handler={goToDeployByABI}/>
                    <ButtonCreate  text="Create" handler={create}/>
                </Stack>
            }
        </div>
      </div>
    )
}
export default Topbar;

