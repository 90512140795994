import styles from "./NFTCard.module.css";

const NFTCard = ({ nft }) => {
    return (
        <div className={styles.NFTCard}>
            <a href={nft.image} target="_blank" rel="noreferrer">
                {nft.isVideo ?
                    <video width="320" height="240" controls>
                        <source src={nft.thumb_url} type="video/mp4" />
                    </video>
               :
               <img
                   className={styles.collectionnameIcon}
                   alt=""
                   src={nft.thumb_url}/>

                }
            </a>
            <h3 className={styles.name}>
                <h3 className={styles.coleccinName}>{nft.name}</h3>
            </h3>
            <a href={nft.metadata_url} target="_blank" rel="noreferrer">
                <div style={
                        { display: "grid", 
                        gridTemplateColumns: "repeat(3, 1fr)" ,
                        gridTemplateRows: "repeat(auto-fit, minmax(1fr, 1fr))",
                        }}>
                    { nft.metadata.map((attribute,index) => {
                        return <small className={styles.metadata} key={index}>
                            { attribute.trait_type} <br />
                            { attribute.trait_type.includes('url') &&
                               < a href={attribute.value} target='_blank' rel="noreferrer">
                                { attribute.value.substring(attribute.value.length-20, attribute.value.length)}
                               </a>
                            }
                            { !attribute.trait_type.includes('image') &&
                                attribute.value
                            }
                        </small>
                    })

                    }
                </div>
            </a>
        </div>
    )
}

export default NFTCard;