import { TextField, Grid, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import styles from "./InputAttributes.module.css";

const InputAttributes = ({ setFieldValue,values, errors,touched }) => {
    const [countAttributes, setCountAttributes] = useState(0)
    useEffect(() => {
        if(values.attributes.length === 1) {
            setCountAttributes(0)
        }
    },[values.attributes])
    return (
        <div style={{width: '100%', textAlign: 'left'}}>
            <div className={styles.price}>
                <label style={{width: '100%'}}>Attributes</label>
                <Grid container spacing={2}>
                    <Grid item xs={5}>
                        <TextField
                            name={`attributes.${countAttributes}.name`}
                            placeholder="Name"
                            style={{width: '100%'}}
                            value={ values.attributes[countAttributes]?.name}
                            onChange={(e) => setFieldValue(`attributes.${countAttributes}.name`, e.target.value)}
                            error = {
                                (errors.attributes && touched.attributes && errors.attributes[countAttributes]?.name && touched?.attributes[countAttributes]?.name)
                            }/>
                        {errors.attributes && touched.attributes && errors.attributes[countAttributes]?.name && touched.attributes[countAttributes]?.name && (
                            <div className={styles.error}>{errors.attributes[countAttributes]?.name}</div>
                        )}
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            name={`attributes.${countAttributes}.value`}
                            placeholder="Attribute value"
                            style={{width: '100%'}}
                            value={ values.attributes[countAttributes]?.value}
                            onChange={(e) => setFieldValue(`attributes.${countAttributes}.value`, e.target.value)}
                            error = {(errors.attributes && touched.attributes && errors.attributes[countAttributes]?.value && touched?.attributes[countAttributes]?.value)}/>
                        {errors.attributes && touched.attributes && errors.attributes[countAttributes]?.value && touched.attributes[countAttributes]?.value && (
                            <div className={styles.error}>{errors.attributes[countAttributes]?.value}</div>
                        )}
                    </Grid>
                    <Grid item xs={2}>
                        <Button  variant="contained" size="large" onClick={() => {
                            let value = values.attributes
                            setCountAttributes(values.attributes.length)
                            value[value.length -1].id = value.length
                            value[values.attributes.length] = {
                                value : '', name : ''
                            }
                            console.log('attributes',value)
                            setFieldValue("attributes", value);
                        }}>
                            Add
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default InputAttributes;