import * as React from 'react';
import UserContext from "context/userContext";
import { Formik, Form } from "formik";
import { useContext, useState, useEffect } from "react";
import {  useParams } from "react-router-dom";
import styles from "./MintOne.module.css";
import * as Yup from "yup";
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import Process from "components/Process";
import Success from "components/Success";
import ErrorContext from "context/ErrorContext";
import { handleErrors } from "services/messages";
import Error from "components/Error";
import { Grid, Button } from '@mui/material';
import InputText from 'components/InputText';
import InputNumber from 'components/InputNumber';
import InputRadio from 'components/InputRadio';
import InputFile from 'components/InputFile';
import InputAttributes from 'components/InputAttributes';
import { ShowMedia } from 'components/CreateEditCollectionForm/CreateEditCollectionForm';
import PreviewAttributes from 'components/PreviewNFT/PreviewAttributes';
import { find } from 'services/collection';
import PreviewABI from 'components/PreviewABI';
import ModalPriceConfiguration from 'components/ModalPriceConfiguration';
import ModalWhitelistConfiguration from 'components/ModalWhitelistConfiguration';


const FILE_SIZE = 1000048576; // 1MB in bytes
const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png', 'video/mpeg', 'video/mp4'];
const SUPPORTED_FORMATS_ANIMATION =  ['image/jpg', 'image/jpeg', 'image/png', 'model/gltf+json', 'model/gltf-binary', 'video/webm', 'video/mp4', 'video/x-m4v', 'video/ogg', 'application/ogg'];


const initialValues = {
    name: "",
    description: "",
    externalUrl : "",
    copies : 0,
    attributes : [
        {
            value : '', name : ''
        }
    ],
    asset: null,
    animation: '',
    isAnimation: false,
  };

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  description: Yup.string().required("Description is required"),
  externalUrl: Yup.string().url("Invalid URL"),
  copies: Yup.number().min(1,"Must be a number more than zero").required("Copies is required"),
  asset: Yup.mixed().required("Image/Video is required")
    .test('fileSize', 'File size too large', (value) => {
        if (value) {
            return value.size <= FILE_SIZE;
        }
        return true;
    })
    .test('fileFormat', 'Unsupported file format', (value) => {
        if (value) {
            return SUPPORTED_FORMATS.includes(value.type)
        }
        return true;
    }),
  animation: Yup.mixed().when('$isAnimation', {
    is: true,
    then: Yup.mixed()
        .test('fileSize', 'File size too large', (value) => {
            if (!value) {
            return true;
            }
            return value.size <= FILE_SIZE;
        })
        .test('fileFormat', 'Unsupported file format', (value) => {
            if (!value) {
                return true;
            }
            return SUPPORTED_FORMATS_ANIMATION.includes(value.type)
        })
        .required('File is required')
  }),
  isAnimation: Yup.boolean(),
  attributes: Yup.array().of(
    Yup.object().shape({
      name: Yup.string(),
      value: Yup.string(),
    })
  )
});

const MintOne = () => {

  const { domain, key } = useParams();
  const [collection ,setCollection] =  useState([])
  const [openConfigPrice ,setOpenConfigPrice] =  useState(false)
  const { user} = useContext(UserContext);
  const { error, setError } = useContext(ErrorContext);
  const [msg, setMsg] = useState({})

  useEffect(() => {
    const getCollection = async() =>{
      if(user && domain) {
        try {
          let newCollections = await find(domain,key)
          setCollection(newCollections)
        } catch (error) {
          setError(handleErrors(error))
          setMsg({})
        }
      }
    }
    getCollection()
  },[user, domain, key, setError]);

  const handleCloseConfigPrice = () => {
    setOpenConfigPrice(false)
  }


  const onSubmit = async(values, { setSubmitting }) => {
    try {
        setOpenConfigPrice(true)
        setCollection(values)
    } catch (error) {
      setError(handleErrors(error))
      setMsg({})
      setSubmitting(false)
    }
  };

  return (
    <> { msg.process == null  && msg.success == null && !error &&
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        className={styles.home}
      >
        {({ values,handleChange, errors, touched, setFieldValue }) => (
            <Grid container spacing={2} style={{maxWidth : '1800px'}}>
                <Grid item xs={12} md={6}>
                    <h5 className={styles.emptyCollections} style={{ textAlign: 'center' }}>Create NFT</h5>
                    <Form className={styles.sectioncollection}>
                        {['name','description','externalUrl'].map((name,index) => (
                            <InputText
                                key={index}
                                rows={(name === 'description') ? 4 : 0}
                                multiline = {(name === 'description')}
                                handleChange = {handleChange}
                                errors = {errors}
                                touched = {touched}
                                name={name}
                                textAlign = {'left'}
                            />
                        ))
                        }
                        <div style={{width: '100%', textAlign: 'left'}}>
                            <Grid container spacing={2}>
                                <Grid item xs={9}>
                                    <InputNumber
                                        handleChange = {handleChange}
                                        errors = {errors}
                                        touched = {touched}
                                        name={'copies'}
                                        textAlign = {'left'}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <InputRadio
                                        setFieldValue={setFieldValue}
                                        errors = {errors}
                                        touched = {touched}
                                        name={'isAnimation'}
                                        title={'Animation'}
                                        textAlign = {'center'}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                        <InputFile
                            setFieldValue={setFieldValue}
                            errors = {errors}
                            touched = {touched}
                            name={'asset'}
                            title={'Asset video/image'}
                            textAlign = {'left'}
                        />
                        { values.isAnimation &&
                            <InputFile
                                setFieldValue={setFieldValue}
                                errors = {errors}
                                touched = {touched}
                                name={'animation'}
                                title={'Asset animation'}
                                textAlign = {'left'} />
                        }
                        { values &&
                            <InputAttributes
                                setFieldValue={setFieldValue}
                                values = {values}
                                errors = {errors}
                                touched = {touched}
                            />
                        }
                        { key && false &&
                            <ModalPriceConfiguration
                                collection = {collection}
                                open = {openConfigPrice}
                                key = {key}
                                domain = {domain}
                                user = {user}
                                handleClose = {handleCloseConfigPrice} />
                        }
                        { key &&
                            <ModalWhitelistConfiguration
                                collection = {collection}
                                open = {openConfigPrice}
                                key = {key}
                                domain = {domain}
                                user = {user}
                                handleClose = {handleCloseConfigPrice} />
                        }
                        <Button
                            type="submit"
                            variant="contained"
                            color="success"
                            size="large">Next</Button>
                    </Form>
                </Grid>
                <Grid item xs={12} md={6}>
                    <h5 className={styles.emptyCollections} style={{ textAlign: 'center' }}>Preview NFT</h5>
                    {values.asset &&
                        <ShowMedia
                            imgClass={styles.imgTumbnail}
                            videoClass={styles.videoThumbnail}
                            source={values.asset} />
                    }
                    <PreviewAttributes setFieldValue={setFieldValue} attributes={values.attributes} />
                </Grid>
            </Grid>
        )}
      </Formik>
    }
    {  msg.process && !error &&
      <Process
        title={msg.process.title}
        message={msg.process.message} />
    }
    { msg.success && !error &&
    <>
      <Success
        icon={faCheck}
        title={msg.success.title}
        message={msg.success.message}
        explorer={msg.success.explorer}
        />
        <PreviewABI
            contractId={5}
        />
    </>
    }

    { error &&
      <Error
        message = {error.message}
        error={error.status}
        title = { error.title} />
    }
    </>
  );
};

export default MintOne;