import * as Yup from "yup";
import { Formik, Form, FieldArray, Field } from "formik";
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent';
import {  FormControlLabel, Grid, Switch, TextField, useTheme } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import styles from "./ModalPriceConfiguration.module.css";
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import { create } from "services/nfts";
import { updateProjectKey, find } from "services/collection";
import { contractData, deployERC721WithWhiteList, sign } from "services/blockchain";
import { uploadMetadata } from "services/minter";
import { uploadAsset } from "services/thumbnail";
import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ErrorContext from "context/ErrorContext";
import Process from "components/Process";
import Success from "components/Success";
import Error from "components/Error";
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { handleErrors } from "services/messages";
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { findWalletsByEmail } from "services/user";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;
    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}
const initialValues = {
    whitelist: [],
   // manager : '0x3E8368344f5B031a8D787272FA3089590f6DFc55'
}

const validationSchema = Yup.object().shape({
   // manager: Yup.string().required('Required manager'),
    whitelist: Yup.array().of(Yup.object().shape({
        email: Yup.string().required('Required email'),
        mintAmount: Yup.number().min(1, 'Must be more than zero'),
        notLimited: Yup.boolean(),
    })).test('unique-emails', 'Emails must be unique', function (value) {
        return new Set(value.map(item => item.email)).size === value.length;
    }),
});

export default function ModalWhitelistConfiguration({ collection, open, handleClose, user }) {
    const { domain, key } = useParams();
    const [msg, setMsg] = useState({})
    const { error, setError } = useContext(ErrorContext);
    const navigate = useNavigate();
    const theme = useTheme();

    const isVideo = (file) => {
        return (file && file.type.startsWith('video/'))
    }

    const onSubmit = async (values, { setSubmitting }) => {
        try {
            if (collection) {
                setMsg({
                    process: {
                        title: 'Upload your images',
                        message: 'Please wait a  moment. This process depends on tha images sizes'
                    }
                })
                let formImage = new FormData();
                formImage.append("files", collection.asset);
                let image_url = await uploadAsset(formImage)
                let animation_url = null;
                let ipfsGateway = 'https://persea.mypinata.cloud/ipfs/';
                if (collection.isAnimation) {
                    formImage = new FormData();
                    formImage.append("files", collection.animation);
                    animation_url = await uploadAsset(formImage)
                    console.log('animation ::', animation_url)
                }
                let attributes = []
                collection.attributes.forEach((attribute) => {
                    if (attribute.name && attribute.value) {
                        attributes.push(attribute)
                    }
                })
                setMsg({
                    process: {
                        title: 'Waiting for signature',
                        message: 'Please ensure that your wallet popup signature is open'
                    }
                })
                let meta = {
                    image: ipfsGateway + image_url,
                    animation_url: (collection.isAnimation) ? ipfsGateway + animation_url : undefined,
                    is_video: isVideo(collection.asset),
                    properties: attributes,
                    levels: [],
                    stats: [],
                    name: collection.name,
                    description: collection.description,
                    categories: [],
                    external_url: '',
                    copies: collection.copies,
                    action: "CREATE_NFT_METADATA"
                }
                setMsg({
                    process: {
                        title: 'Updating persea',
                        message: 'Please wait a moment',
                    }
                })
                let folderHash = await uploadMetadata(domain, key, meta)
                console.log('resMeta ::', folderHash)
                setMsg({
                    process: {
                        title: 'Waiting for signature',
                        message: 'Please ensure that your wallet popup signature is open'
                    }
                })
                let folder = ipfsGateway + folderHash + "/"
                let oldCollection = await find(domain, key)
                const { symbol } = contractData(oldCollection.name)
                const whitelistAddresses =  await findWalletsByEmail(domain,values.whitelist);
                let newWallets = []
                for (let wallet of whitelistAddresses) {
                    newWallets.push(wallet.toLowerCase())
                }
                newWallets.push("0XE1AD1C64D1A8BA9DD417A188031BD6188FF34C65".toLowerCase())
                let whitelistAmounts = values.whitelist.map(item => {
                    if (item.notLimited) {
                        return -1;
                    }
                    return item.mintAmount;
                });
                whitelistAmounts.push(5)
                const transaction = await deployERC721WithWhiteList(
                    user.wallet,
                    user.provider,
                    oldCollection.name,
                    symbol,
                    "1.0",
                    '0x3E8368344f5B031a8D787272FA3089590f6DFc55',
                    collection.copies,
                    folder,
                    newWallets,
                    whitelistAmounts
                )
                setMsg({
                    process: {
                        title: 'Updating persea database with your signature',
                        message: 'Please confirm your signature in your wallet'
                    }
                })
                const { message, signature } = await sign(user.wallet, user.provider, "UPDATE_COLLECTION")
                const nft = {
                    project_key: oldCollection.key,
                    base_uri: folder,
                    thumb_url: meta.image,
                    copies: collection.copies,
                    price: 0,
                    signature: signature,
                    message: message,
                    tags: [],
                    categories: []
                }
                await create(oldCollection.domain, nft)
                await updateProjectKey(oldCollection.domain, oldCollection.blockchain, oldCollection.key, transaction._address, message, signature)
                setMsg({
                    success: {
                        title: 'Creation successful',
                        message: 'Redirected to home in 4s',
                        key: transaction._address.toLocaleUpperCase(),
                        transaction: '',
                        explorer: ''
                    }
                })

                const interval = setInterval(() => {
                    clearInterval(interval)
                    navigate("/" + oldCollection.domain + "/home");
                }, 4000)
            } else {
                console.log('Collection not found', collection)
            }
            console.log(values, setSubmitting, collection)
        } catch (error) {
            setError(handleErrors(error))
        }
    }
    return (
        <div>
            <BootstrapDialog
                maxWidth="md"
                aria-labelledby="customized-dialog-title"
                open={open}>
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Configuration
                </BootstrapDialogTitle>
                {msg.process == null && msg.success == null && !error &&
                    <Formik
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        validationSchema={validationSchema}
                        className={styles.home}
                    >
                        {({ values, setFieldValue }) => (
                            <Form>
                                <DialogContent dividers >
                                  {/*   <Box sx={{ marginBottom: 4, maxWidth: (values.whitelist && values.whitelist.length === 0) ? "100%" : "50%" }}>
                                        <InputLabel htmlFor="manager">Manager</InputLabel>
                                        <Field
                                            id="manager"
                                            name="manager"
                                            placeholder="Email "
                                            fullWidth
                                            disabled
                                            // type="email" //not email for now
                                            component={WrappedTextField}
                                        />
                                    </Box> */}
                                  {/*   <InputLabel>Whitelist</InputLabel> */}
                                    <FieldArray
                                        name="whitelist"
                                        render={arrayHelpers => (
                                            <Grid container rowSpacing={3}>
                                                {values.whitelist && values.whitelist.length > 0 ? (
                                                    values.whitelist.map((_, index) => (
                                                        <Grid item xs={12} key={index}>
                                                            <Grid container key={index} alignItems="flex-start" columnSpacing={2} rowSpacing={1}>
                                                                <Grid item xs={6}>
                                                                    <Field
                                                                        name={`whitelist.${index}.email`}
                                                                        placeholder="Email"
                                                                        fullWidth
                                                                        // type="email" //not email for now
                                                                        component={WrappedTextField}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={2}>
                                                                    <Field
                                                                        name={`whitelist.${index}.mintAmount`}
                                                                        placeholder="Amount"
                                                                        type="number"
                                                                        disabled={values.whitelist[index].notLimited}
                                                                        component={WrappedTextField}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    <FormControlLabel
                                                                        label="No limit"
                                                                        control={
                                                                            <Switch
                                                                                onChange={(_, checked) => {
                                                                                    setFieldValue(`whitelist.${index}.notLimited`, checked);
                                                                                    setFieldValue(`whitelist.${index}.mintAmount`, '');
                                                                                }}
                                                                            />
                                                                        }
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Grid container alignItems="center" spacing={theme.spacing(2)}>
                                                                        <Grid item>
                                                                            <Button
                                                                                type="button"
                                                                                variant="outlined"
                                                                                size="large"
                                                                                startIcon={<DeleteIcon />}
                                                                                onClick={() => arrayHelpers.remove(index)}
                                                                            >
                                                                                Remove
                                                                            </Button>
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <Button
                                                                                type="button"
                                                                                variant="contained"
                                                                                size="large"
                                                                                startIcon={<AddIcon />}
                                                                                onClick={() => arrayHelpers.push({ email: '', mintAmount: 1, notLimited: false})}
                                                                            >
                                                                                Add
                                                                            </Button>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    ))
                                                ) : (
                                                    <Grid item sx={12}>
                                                        <Button
                                                            type="button"
                                                            variant="contained"
                                                            size="large"
                                                            onClick={() => arrayHelpers.push({ email: '', mintAmount: 1, notLimited: false})}
                                                        >
                                                            Add someone to the whitelist
                                                        </Button>
                                                    </Grid>
                                                )}
                                                {/* <ErrorMessage name="whitelist" /> */}
                                            </Grid>
                                        )}
                                    />
                                </DialogContent>
                                <DialogActions>
                                    <Button type="submit">
                                        Confirm
                                    </Button>
                                </DialogActions>
                            </Form>
                        )}
                    </Formik>

                }
                <div className={styles.parentcontainer} id="parent">
                    {msg.process && !error &&
                        <Process
                            title={msg.process.title}
                            message={msg.process.message} />
                    }
                    {msg.success && !error &&
                        <>
                            <Success
                                icon={faCheck}
                                title={msg.success.title}
                                project_key={msg.success.project_key}
                                message={msg.success.message}
                                explorer={msg.success.explorer}
                            />
                        </>
                    }

                    {error &&
                        <Error
                            message={error.message}
                            error={error.status}
                            title={error.title} />
                    }

                </div>

            </BootstrapDialog>
        </div>
    )
}

const WrappedTextField = ({ field, form: { touched, errors }, ...props }) => {
    const keys = field.name.split('.');

    const getTouched = () => {
        let targetValue = touched;
        for (const key of keys) {
            try {
                targetValue = targetValue[key];
            } catch (error) {
                return false;
            }
        }
        return targetValue;
    }

    const getError = () => {
        if (!getTouched()) return "";
        let targetValue = errors;
        for (const key of keys) {
            try {
                targetValue = targetValue[key];
            } catch (error) {
                return "";
            }
        }
        return targetValue;
    }

    return (
        <TextField
            {...field}
            {...props}
            error={getTouched() && getError()}
            helperText={getError()}
        />
    )
}