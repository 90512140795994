import ConfirmDeployWithReservedTokensAndClaimants from "components/ConfirmDeployWithReservedTokensAndClaimants";
import SectionPreviewYearDiv from "components/SectionPreviewYearDiv";

const MintBatchWeeksGasless = ({ mintStatus, handlePreview, handleConfirm}) => {
    return (
        <>  { mintStatus  === 0 &&
                <SectionPreviewYearDiv  handlePreview = {handlePreview} />
            }
            {mintStatus  === 2 &&
                <ConfirmDeployWithReservedTokensAndClaimants
                    handleConfirm={handleConfirm} 
                    handlePreview = {handlePreview}/>
            }
        </>
    )
}

export default MintBatchWeeksGasless;
