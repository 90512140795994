import UserContext from "context/userContext";
import { Formik, Form } from "formik";
import { useCallback, useContext, useEffect, useState } from "react";
import { contractData, findAllByDomain, sign } from "services/blockchain";
import { uploadBanner, uploadThumbnail } from "services/thumbnail";
import styles from "./CreateEditCollectionForm.module.css";
import * as Yup from "yup";
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import Process from "components/Process";
import Success from "components/Success";
import ErrorContext from "context/ErrorContext";
import { handleErrors } from "services/messages";
import Error from "components/Error";
import DescriptionEditor from "./DescriptionEditor";
import { useParams } from "react-router-dom";
import { Grid } from '@mui/material';
import InputText from "components/InputText";
import InputSelect from "components/InputSelect";
import InputFile from "components/InputFile";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  blockchain: Yup.string().required("Description is required"),
//  description: Yup.string().required("Blockchain is required"),
  image: Yup.mixed().required("Thumbnail is required"),
  banner: Yup.mixed().required("Banner is required"),
});

const CreateEditCollection = ({ submitButton, manageSubmit, initialValues, editBlockchain = true }) => {

  const { domain } = useParams();
  const { user } = useContext(UserContext);
  const { error, setError } = useContext(ErrorContext);
  const [msg, setMsg] = useState({});
  const [HTMLDescription, setHTMLDescription] = useState("<p></p>");
  const [isValidDescription, setIsValidDescription] = useState(true);
  console.log(isValidDescription)
  const [blockchains, setBlockchains] = useState({ isLoading: true, data: [] });
  useEffect(() => {
    let ignore = false;
    async function fetchBlockchains() {
      if (!ignore) {
        const result = await findAllByDomain(domain,false);
        setBlockchains({ isLoading: false, data: result });
      }
    }
    fetchBlockchains()
    return () => {
      ignore = true;
    }
  }, [editBlockchain, domain]);

  const handleDescriptionState = (newHTMLDescription) => {
    setHTMLDescription(newHTMLDescription);
    evaluateDescription();
  }

  const evaluateDescription = () => {
    if (HTMLDescription === "<p></p>\n" || HTMLDescription.length < 15) {//TODO: This is an arbitrary lenght, you can do it better
      setIsValidDescription(false);
      return false;
    }
    setIsValidDescription(true);
    return true
  }

  const onSubmit = async (values, { setSubmitting }) => {
    //if (!evaluateDescription()) {
    //  return;
    //}
    try {
      setMsg({
        process: {
          title: 'Upload your images',
          message: 'Please wait a  moment. This process depends on tha images sizes'
        }
      })
      const thumb_url = await uploadThumbnailIfNeeded(values.image);
      const banner_url = await uploadBannerIfNeeded(values.banner);
      const isVideo = await determineMediaType(thumb_url);
      setMsg({
        process: {
          title: 'Waiting for signature',
          message: 'Please ensure that your wallet popup signature is open'
        }
      })
      let { signature, message } = await sign(user.wallet, user.provider)
        .catch((err) => { console.error('sign error', err) });
      let { symbol } = contractData(values.name);
      const projectKey = (signature.substring(1, 42) + symbol)
      let collection = {
        address_collectible: projectKey,
        project_key: projectKey,
        transaction: signature,
        only_domain: 1,
        status: 1,
        imported: 0,
        standard : 'ERC721',
        tags: [],
        categories: [],
        social_networks: [],
        files_url: [],
        commission: [],
        external_url: '',
        description: HTMLDescription,
        name: values.name,
        thumb_url: thumb_url,
        banner_url: banner_url,
        signature: signature,
        message: message,
        is_video: isVideo ? 1 : 0,
        domain: domain,
        action: 'CREATED_PROJECT',
        blockchain_name: values.blockchain,
        thumb_second: -1,
        extra_details: '',
      }
      setMsg({
        process: {
          title: 'Updating persea',
          message: 'Please wait a moment',
        }
      })
      await manageSubmit(collection)
      setMsg({
        success: {
          title: 'Creation successful',
          message: 'Redirect to home in 4s',
          key: '',
          transaction: '',
          explorer: ''
        }
      })
      setSubmitting(false);
    } catch (error) {
      setError(handleErrors(error))
      setMsg({})
      setSubmitting(false)
    }
  };

  const uploadThumbnailIfNeeded = async (thumbnail) => {
    if (isURL(thumbnail)) return thumbnail;
    const formThumb = new FormData();
    formThumb.append("thumbnail", thumbnail);
    const thumb_url = await uploadThumbnail(domain, formThumb, (thumbnail.type.includes('mp4')))
    return thumb_url;
  }

  const uploadBannerIfNeeded = async (banner) => {
    if (isURL(banner)) return banner;
    const formBanner = new FormData();
    formBanner.append("files", banner);
    const banner_url = await uploadBanner(domain,formBanner)
    return banner_url;
  }

  const isURL = (obj) => {
    return (typeof (obj) === "string")
  }


  return (
    <> {msg.process == null && msg.success == null && !error &&
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            className={styles.home}>
            {({ values,handleChange, errors, touched, setFieldValue}) => (
            <Grid container spacing={2} style={{maxWidth : '1800px'}}>
                <Grid item xs={12} md={6}>
                    <h5 className={styles.emptyCollections} style={{ textAlign: 'center' }}>Create project</h5>
                    <Form className={styles.form}>
                        {['name'].map((name,index) => (
                            <InputText
                                key={index}
                                rows={0}
                                multiline = {false}
                                handleChange = {handleChange}
                                errors = {errors}
                                touched = {touched}
                                name={name}
                                textAlign = {'left'}
                            />
                        ))
                        }

                        {blockchains.data && blockchains.data.length > 0 &&
                            <InputSelect
                                setFieldValue={setFieldValue}
                                errors={errors}
                                touched={touched}
                                name={'blockchain'}
                                title={'Select Blockchain'}
                                textAlign={'left'}
                                values ={values}
                                items={blockchains.data}/>
                        }
                        <div className={styles.formField}>
                            <label htmlFor="description" style={{ width: '100%' }}>Description</label>
                            <DescriptionEditor
                                initialValue={initialValues.description}
                                handleHTMLContent={(content) => handleDescriptionState(content)} />
                        </div>
                        <InputFile
                            setFieldValue={setFieldValue}
                            errors = {errors}
                            touched = {touched}
                            name={'image'}
                            title={'Thumbnail video/image'}
                            textAlign = {'left'}
                        />
                        <InputFile
                            setFieldValue={setFieldValue}
                            errors = {errors}
                            touched = {touched}
                            name={'banner'}
                            title={'Banner video/image'}
                            textAlign = {'left'}
                        />
                        {submitButton}
                    </Form>
                </Grid>
                <Grid item xs={12} md={6}>
                    {values.image &&
                        <ShowMedia
                        imgClass={styles.imgTumbnail}
                        videoClass={styles.videoThumbnail}
                        source={values.image} />
                    }
                </Grid>
            </Grid>
            )}
        </Formik>
    }
      {msg.process && !error &&
        <Process
          title={msg.process.title}
          message={msg.process.message} />
      }
      {msg.success && !error &&
        <Success
          icon={faCheck}
          title={msg.success.title}
          message={msg.success.message}
          explorer={msg.success.explorer}
        />
      }

      {error &&
        <Error
          message={error.message}
          error={error.status}
          title={error.title} />
      }
    </>
  );
};

export const ShowMedia = ({ source, imgClass = "", videoClass = "" }) => {
  const [mediaType, setMediaType] = useState(null);

  const isUrl = useCallback(() => typeof source === "string", [source]);

  const isVideo = () => mediaType === "video";

  const getUrl = useCallback(() => {
    return isUrl() ? source : URL.createObjectURL(source);
  }, [source, isUrl]);

  useEffect(() => {
    let ignore = false;
    async function getMediaType() {
      const determinedMediaType = await determineMediaType(getUrl());
      if (!ignore) {
        setMediaType(determinedMediaType);
      }
    }
    getMediaType();
    return () => {
      ignore = true;
    }
  }, [source,getUrl]);

  return (
    <>
      {isVideo() ?
        <video className={videoClass} controls>
          <source src={getUrl()} type="video/mp4" />
        </video>
        :
        <img
          src={getUrl()}
          alt="Preview"
          className={imgClass}
        />
      }
    </>
  );
}


const determineMediaType = async (url) => {
  const response = await fetch(url);
  const contentType = response.headers.get("content-type");
  if (contentType.startsWith("video/")) return "video";
  return "image";
}

export default CreateEditCollection;
