import ConfirmDeployWithSplitAmount from "components/ConfirmDeployWithSplitAmount";
import SectionPreviewLinksDiv from "components/SectionPreviewLinksDiv";

const MintBatchAndReveal = ({ mintStatus, handlePreview, handleConfirm}) => {
    return (
        <>  { mintStatus  === 0 &&
                <SectionPreviewLinksDiv  handlePreview = {handlePreview} />
            }
            {mintStatus  === 2 &&
                <ConfirmDeployWithSplitAmount handleConfirm={handleConfirm}  handlePreview = {handlePreview}/>
            }
        </>
    )
}

export default MintBatchAndReveal;
