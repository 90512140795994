import styles from "./SectionPreviewLinksDiv.module.css";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Calendar from "./calendar/Calendar";

const SectionPreviewYearDiv = ({handlePreview}) => {

  const handleSubmit = (values) => {
    console.log(`Submitting form with URLs: ${values.doc} and ${values.folder}`);
    handlePreview(values);
    // You can perform further actions with the URLs here, such as making API requests
  };

  return (
    <Formik
    initialValues={{ year: ''}}
    onSubmit={handleSubmit}
    className={styles.sectionpreviewlinks}
    validationSchema={Yup.object({
      year: Yup.string().required('Year is required').min(4),
    })}>
      {({ setFieldValue}) => (
        <Form  className={styles.sectionpreviewlinks} style={{alignItems: "center"}}>
          <Calendar
              type='only-years' 
              label="Select a year"
              setDate={(year) => setFieldValue('year', year)}
          />
          <button className={styles.previewbutton} type="submit">
            <div className={styles.preview}>Preview</div>
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default SectionPreviewYearDiv;
