import { create } from "services/collection";
import CreateEditCollection from "components/CreateEditCollectionForm/CreateEditCollectionForm";
import { useNavigate, useParams } from "react-router-dom";
import {  Button } from '@mui/material';

const initialValues = {
  name: "",
  thumb: null,
  blockchain: "0",
  banner: null,
};

const CreateCollection = () => {

  const { domain } = useParams()
  const navigate = useNavigate();

  const submit = async (collection) => {
    await create(collection);
    navigate(`/${domain}/home`);
  }

  const submitButton = () => {
    return (
                        <Button
                            type="submit"
                            variant="contained"
                            color="success"
                            size="large">Create</Button>
    );
  }

  return (
    <CreateEditCollection
      submitButton={ submitButton() }
      manageSubmit={ (collection) => submit(collection) }
      initialValues={ initialValues }
      />
  );
};

export default CreateCollection;