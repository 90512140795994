import UserContext from "../../context/userContext";
import styles from "./ContainerAnalytics.module.css";
import React,{ useContext, useEffect, useState } from "react";
import { findAllWithAbout } from "services/user";
import { DataGrid } from '@mui/x-data-grid';
import ErrorContext from "context/ErrorContext";
import Process from "components/Process";
import Error from "components/Error";
import { handleErrors } from "services/messages";
import { useParams } from "react-router-dom";

const columns = [
  { field: 'id', headerName: 'id', width: 70 },
  { field: 'nft', headerName: 'NFT', width: 150 },
  { field: 'email', headerName: 'Email', width: 280 },
  { field: 'phone', headerName: 'Phone', width: 130 },
  {
    field: 'newsLetter',
    headerName: 'New letter',
    width: 160,
    valueGetter: (params) =>
      `${(params.row.newsLetter) ? 'Accepted' : 'rejected'}`,
  },
];

const ContainerAnalytics = () => {

  const { domain, projectKey } = useParams();
  const { error , setError } = useContext(ErrorContext);
  const { user} = useContext(UserContext);
  const [users ,setUsers] =  useState([])
  const [msg, setMsg] = useState({})

  useEffect(() => {
    const getUsers = async() =>{
      try {
        let newUsers = await findAllWithAbout(domain)
        setUsers(newUsers)
        console.log('users', newUsers)
      } catch (error) {
        setError(handleErrors(error))
        setMsg({})
      }
    }
    getUsers()
  },[domain, setError]);

  if(users.length === 0) {
    return (
      <>
        { !msg.process && !error &&
          <>
            <h1 className={styles.title}>Analytics</h1>
            <div className={styles.home}>
              <h5 className={styles.emptyusers} style={{textAlign:'center'}}>
               Sorry we dont have any  analytics
              </h5>
{/*               <div style={{ margin: '1px auto', height: '10%'}}>
                <Button text="Create" handler={create}/>
              </div> */}
            </div>
          </>
        }
        {msg.process && !error &&
          <Process
          title={msg.process.title} 
          message={msg.process.message} />
        }
        { error && projectKey &&
          <Error
            message = {error.message}
            error={error.status}
            title = { error.title} 
            key = {projectKey}/>
        }
      </>
    )
  }
  return (
     user &&
    <>
      <h1 className={styles.title}>Analytics</h1>
      <div className={styles.home}>
        <div className={styles.sectioncollection}>
          <h5 className={styles.emptyusers}>Usuarios con nfts</h5>
          {users.length > 0 &&
           <div style={{ height: 500, width: '100%' }}>
             <DataGrid
                 rows={users}
                 columns={columns}
                 pageSize={10}
                 rowsPerPageOptions={[10]}
               />
           </div>
          }
          { error && projectKey &&
            <Error
              message = {error.message}
              error={error.status}
              title = { error.title} 
              key = {projectKey}/>
          }
        </div>
      </div>
    </>
  );
};

export default ContainerAnalytics;
