import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const Process = ({ title,message, size="4x"}) => {
    return (
        <div style={{textAlign: 'center', padding:'10px 0px 12px 0px', margin:'auto'}}>
            <h3>{title}</h3>
            <FontAwesomeIcon icon={faSpinner} size={size} color="#000"/>
            <h4>{message}</h4>
        </div>
    )
}

export default Process;