import styles from "./ConfirmDeployCard.module.css";
import { Formik, Form, Field, FieldArray, /* ErrorMessage */ } from 'formik';
import * as Yup from 'yup';
import { useState } from "react";
import { DateTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { uploadAsset } from "services/thumbnail";
import { updateExtraDetails } from "services/collection";
import { useParams } from "react-router-dom";
import { findAllByDomain } from "services/blockchain";

Yup.addMethod(Yup.array, 'unique', function(message,  mapper = a => a) {
  return this.test('unique', message, (value) => {
      return value.length === new Set(value.map(mapper)).size;
    });
});

const validationSchema =  Yup.object({
  version: Yup.string().required('Required collection version'),
  collectionURL: Yup.string().url(),
  reserved: Yup.array().of(Yup.number().min(1).required('Required token ID')).unique('Required unique token IDs'),
  claimants: Yup.array().of(Yup.object({
    wallet: Yup.string().required('Required claimant wallet'),
    amount: Yup.number().min(1).required('Required claimant amount'),
    startDate: Yup.date().min(new Date()).required('Required claimant start date')
  })),
  // sliders: Yup.array().of(Yup.number().min(1).required('Required token ID')).unique('Required unique token IDs'),
  sliders: Yup.array().of(Yup.mixed().required("Required slider")).min(1, "You need at least one Slider").max(3, "You have reached the maximum number of Sliders"),
  map: Yup.mixed().required("Required map"),
})

const initialValues = { 
  version: '1',
  collectionURL: '',
  reserved: [],
  claimants: [
    { wallet: "", amount: 0, startDate: "" }
  ],
  sliders: [],
  map: "",
}

const ConfirmDeployWithReservedTokensAndClaimants = ({handleConfirm, handlePreview}) => {
  const [claimantsCount, setClaimantsCount] = useState([0]);
  const { domain, projectKey } = useParams();

  const handleSubmit = async (values) => {
    console.log("Values: ", values);
    const uploadFiles = async () => {
      console.log("Values: ", values);

      const sliderURLs = [];
      for (const file of values.sliders) {
        const form = new FormData();
        form.append("files", file);
        sliderURLs.push(await uploadAsset(form));
      }

      const form = new FormData();
      form.append("files", values.map);
      const mapURL = await uploadAsset(form);

      const extraDetails = {
        sliders: sliderURLs,
        map: mapURL,
      }

      console.log(sliderURLs);
      console.log(mapURL);

      const blockchains = findAllByDomain(domain)

      await updateExtraDetails(domain, blockchains[0], projectKey, JSON.stringify(extraDetails), "firm", "message");
    }
    handleConfirm({
      version: values.version,
      contractURI: values.collectionURL,
      reservedNFTS: values.reserved,
      claimants: values.claimants.map((claimant) => claimant.wallet),
      claimantsConf: values.claimants.map((claimant) => {
        const epochDate = Math.floor(new Date(claimant.startDate).getTime() / 1000);
        return { left: claimant.amount, startDate: epochDate }
      })
    }, uploadFiles);
  };


  const handleAddClaimant = () => {
    if(claimantsCount.length < 3) {
      setClaimantsCount([...claimantsCount, claimantsCount.length]);
    }
  };

  const handleRemoveClaimant = () => {
    if(claimantsCount.length > 1) {
      setClaimantsCount((prevArray) => prevArray.slice(0, -1));
    }
  };

  return (
    <Formik
    initialValues={initialValues}
    onSubmit={handleSubmit}
    className={styles.sectionconfirm}
    validationSchema={validationSchema}>
        {({ errors, touched, values, setFieldValue }) => (
          <Form  className={styles.sectionconfirm}>
            <div className={styles.price}>Colleciton version</div>
            <Field className={styles.sectionconfirmChild} name="version" type="string" style={{width:'99%'}}/>
            {errors.version && touched.version && (
              <div className={styles.error}>{errors.version}</div>
            )}
            <div className={styles.price}>Collection URL</div>
            <Field className={styles.sectionconfirmChild} name="collectionURL" type="string" style={{width:'99%'}}/>
            {errors.collectionURL && touched.collectionURL && (
              <div className={styles.error}>{errors.collectionURL}</div>
            )}

            <div className={styles.price}>Sliders</div>
            <FieldArray
              name="sliders"
              validateOnChange={true}
              render={arrayHelpers => (
                <div style={{width: '80%'}}>
                 {values.sliders && values.sliders.length > 0 ? (
                   values.sliders.map((_, index) => (
                     <div key={index} style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                      <label htmlFor={`sliders.${index}`}>{values.sliders[index].name}</label>
                      <input
                        id={`sliders.${index}`}
                        name={`sliders.${index}`}
                        type="file"
                        accept="image/*,video/*"
                        onChange={(event) => {
                          setFieldValue(`sliders.${index}`, event.currentTarget.files[0]);
                        }}
                        style={{ width: '100%', display: 'none' }}
                      />
                      <div style={{display: 'flex', flexDirection: 'row'}}>
                       <button
                         type="button"
                         onClick={() => arrayHelpers.remove(index)}>
                         Remove
                       </button>
                       <button
                         type="button"
                         onClick={() => arrayHelpers.push({name: "Browse"})}>
                         Add
                       </button>
                      </div>
                     </div>
                   ))
                 ) : (
                   <button type="button" onClick={() => arrayHelpers.push({name: "Browse"})}>
                     Add a slider
                   </button>
                 )}
               </div>
              )}
            />
            {errors.sliders && touched.sliders && (
              <div className={styles.error}>{errors.sliders}</div>
            )}

            <div className={styles.price}>Map</div>
            <input
              name="map"
              type="file"
              accept="image/*,video/*"
              onChange={(event) => {
                setFieldValue('map', event.currentTarget.files[0]);
              }}
              style={{ width: '100%'}}
            />
            {errors.map && touched.map && (
              <div className={styles.error}>{errors.map}</div>
            )}

            <div className={styles.price}>Reserved Tokens IDs</div>
            <FieldArray
              name="reserved"
              validateOnChange={true}
              render={arrayHelpers => (
                <div>
                 {values.reserved && values.reserved.length > 0 ? (
                   values.reserved.map((_, index) => (
                     <div key={index}>
                       <Field name={`reserved.${index}`} />
                       <button
                         type="button"
                         onClick={() => arrayHelpers.remove(index)}>
                         Remove
                       </button>
                       <button
                         type="button"
                         onClick={() => arrayHelpers.push('')}>
                         Add
                       </button>
                     </div>
                   ))
                 ) : (
                   <button type="button" onClick={() => arrayHelpers.push('')}>
                     Add a token ID
                   </button>
                 )}
               </div>
              )}
            />
            {errors.reserved && touched.reserved && (
              <div className={styles.error}>{errors.reserved}</div>
            )}
            <div className={styles.price}>
              Claimants
              <button type="button" onClick={handleAddClaimant}>+</button>
              <button  type="button" onClick={handleRemoveClaimant}>-</button>
            </div>
            {claimantsCount.map((_,index) => (
              <div key={index} className={styles.sectionconfirm} style={{flexDirection: 'row'}}>
                <div style={{width:'45%'}}>
                  <div className={styles.price}>Wallet {index +1}</div>
                  <Field
                    name={`claimants.${index}.wallet`}
                    type="text"
                    placeholder={'Wallet '+(index + 1)}
                    className={styles.sectionconfirmChild}
                    style={{width:'100%'}}
                  />
                  {touched.claimants && touched.claimants[index]?.wallet && errors.claimants && errors.claimants[index]?.wallet && 
                    <div className={styles.error}>{errors.claimants?.[index].wallet}</div>
                  }
                </div>
                <div style={{width:'15%'}}>
                  <div className={styles.price}>Amount {index +1}</div>
                  <Field
                    name={`claimants.${index}.amount`}
                    type="number" step="1" min="1" max="100"
                    placeholder="Amount"
                    className={styles.sectionconfirmChild}
                    style={{width:'100%'}}
                  />
                  {touched.claimants && touched.claimants[index]?.amount && errors.claimants && errors.claimants[index]?.amount &&
                  (
                    <div className={styles.error}>{errors.claimants[index].amount}</div>
                  )
                  }
                </div>
                <div style={{width:'40%'}}>
                  <div className={styles.price}>Start date {index +1}</div>
                  <StartDateField
                    onChange={(value) => setFieldValue(`claimants.${index}.startDate`, value, true)}/>
                  {touched.claimants && touched.claimants[index]?.startDate && errors.claimants && errors.claimants[index]?.startDate &&
                  (
                    <div className={styles.error}>{errors.claimants[index]?.startDate}</div>
                  )
                  }
                </div>
              </div>
              ))
            }
            {touched.wallets && errors.totalPercentage &&
              <div className={styles.error}>{errors.totalPercentage}</div>
            }
            {touched.wallets && errors.walletDouble &&
              <div className={styles.error}>{errors.walletDouble}</div>
            }
            <div className={styles.deploybuttongroup}>
              <button className={styles.confirmbutton} type="submit">
                <div className={styles.confirm}>Confirm</div>
              </button>
              <button className={styles.confirmbutton1} onClick={ () => { handlePreview(null) }}>
                <div className={styles.confirm}>Reload</div>
              </button>
            </div>
          </Form>
        )}
    </Formik>
  );
};

const StartDateField = ({...props}) => {
  return (
    <DateTimePicker
      className={styles.price}
      minDate={dayjs(new Date())}
      defaultValue=""
      {...props} />
  );
}

export default ConfirmDeployWithReservedTokensAndClaimants;
