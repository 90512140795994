import ConfirmDeployCardWithoutSell from "components/ConfirmDeployCardWithoutSell";
import SectionPreviewLinksDiv from "components/SectionPreviewLinksDiv";

const OnlyMintBatch = ({ mintStatus, handlePreview, handleConfirm}) => {
    return (
        <>  { mintStatus  === 0 &&
                <SectionPreviewLinksDiv  handlePreview = {handlePreview} />
            }
            {mintStatus  === 2 &&
                <ConfirmDeployCardWithoutSell handleConfirm={handleConfirm}  handlePreview = {handlePreview}/>
            }
        </>
    )
}

export default OnlyMintBatch;
