import Error from "components/Error";
import MintType from "components/MintType";
import Process from "components/Process";
import ErrorContext from "context/ErrorContext";
import UserContext from "context/userContext";
import { useContext, useEffect, useState } from "react";
import { find } from "services/collection";
import { handleErrors } from "services/messages";
import styles from "./SelectMintType.module.css";
import { useParams } from "react-router-dom";
import { MINT_TYPES } from "services/blockchain";

let mintTypes = [
  {
    id : 1,
    name : 'Mint batch and sell',
    description : 'Mint many nfts by csv and folder, and put on sale with same price',
    link : '',
    active : true,
  },
   {
    id : 2,
    name : 'Only mint batch',
    description : 'Mint many nfts by csv and folder',
    link : '',
    active : true,
  },
  {
    id : 3,
    name : 'Mint and reveal',
    description : 'Mint many nfts by csv and folder and reveal only after the mint',
    link : '',
    active : true,
  },
  {
    id : 4,
    name : 'Mint and reveal (Gassless)',
    description : 'Mint many nfts by csv and folder and reveal only after the mint',
    link : '',
    active : true,
  },
  {
    id : 6,
    name : 'Mint weeks',
    description : 'Mint nfts representing all the weeks of a year of your choice',
    link : '',
    active : true,
  },
  {
    id: MINT_TYPES.mintWithPriceAndPayees,
    name: 'Deploy with price and payees',
    description: 'Deploy a contract with price and payees',
    link: '',
    active: true,
  }
  /*
  {
    id : 4,
    name : 'Mint batch and reveal',
    description : 'Mint many nfts by csv and folder, and put on sale with same price',
    link : '',
    active : false,
  },
  {
    id : 5,
    name : 'Mint batch and reveal at date',
    description : 'Mint many nfts by csv and folder, and put on sale with same price',
    link : '',
    active : false,
  } */
]
const SelectMintType = () => {

  const { domain, projectKey } = useParams()
  const { error , setError } = useContext(ErrorContext);
  const { user} = useContext(UserContext);
  const [collection ,setCollection] =  useState([])
  const [msg, setMsg] = useState({})

  useEffect(() => {
    const getCollection = async() =>{
      if(user) {
        try {
          setMsg({ process : {
            title: 'Loading your collection',
            message: 'We show your mint type available in  moments'
          }})
          let newCollections = await find(domain,projectKey)
          setCollection(newCollections)
          setMsg({})
          setError(null)
        } catch (error) {
          setError(handleErrors(error))
          setMsg({})
        }
      }
    }
    getCollection()
  },[user, domain, projectKey, setError]);

  return (
    <>
      {user && collection && !msg.process && !error &&
        <>
          <h1 className={styles.title}>Select mint type</h1>
          <div className={styles.sectionselectminttype}>
            {
              mintTypes.map( (item) => {
                return <MintType mintType={item} collection= {collection} key={item.id} />
              })
            }
          </div>
        </>
      }
      {msg && msg.process && !error &&
        <Process
          title={msg.process.title}
          message={msg.process.message} />
      }
      { error && projectKey &&
        <Error
          message = {error.message}
          error={error.status}
          title = { error.title}
          key = {projectKey}/>
      }
    </>
  )
}

export default SelectMintType;