import axios from "axios";

const url = process.env.REACT_APP_API+"nft/massive"

export const findAllByDomain = (domain) => {
    return axios.get(url+"?domain="+domain).then(res=>res.data)
}

export const findAllByCreator = async(domain, wallet) => {
    return axios.get(url+"?domain="+domain+/* "&creator="+wallet+" */"&order=created&limit=30&page=0").then(res=>{
       let collections = []
       res.data.forEach((collection) => {
        console.log(collection.blockchain)
            collections.push({
                name : collection.name,
                thumb_url : collection.thumb_url,
                key : collection.project_key,
                domain : collection.domain,
                blockchain : collection.blockchain.blockchain_name,
                explorer : collection.blockchain.explorer
            })
       })
        return collections
    })
}

export const findAll = async(domain, key) => {
    return axios.get(url+"?domain="+domain+ "&project_key="+key+"&order=created&limit=30&page=0").then(res=>{
       let nfts = []
       if(res.data) {
           res.data.forEach((nft) => {
                nfts.push({
                    name : nft.metadata.json_data.name,
                    metadata : nft.metadata.json_data.attributes,
                    isVideo : nft.metadata.json_data.isVideo,
                    thumb_url : nft.thumb_url,
                    metadata_url : nft.metadata.metadata_url,
                    image : nft.metadata.image_url
                })
           })
            return nfts
       }
    })
}

export const validateDocAndFolder = async(domain, key, data) => {
    let url = `${process.env.REACT_APP_API}nft/massive?project_key=${key}&action=create&domain=${domain}&create_from=sheet`
    return axios.post(url,data).then(res=>{
        return res
    })
}

export const validateWeeksOfTheYear = async (domain, data) => {
    let url = `${process.env.REACT_APP_API}nft/massive/templates/weeks-of-the-year?domain=${domain}`
    return axios.post(url, data).then(res=>{
        return res
    })
}

export const uploadMetadata = async(domain,key,data) => {
    let url = `${process.env.REACT_APP_API}/nft/metadata?domain=${domain}&project_key=${key}`
    return axios.post(url,data).then(res=>{
        return res.data.folder_hash
    })
}
