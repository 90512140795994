import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import dayjs from 'dayjs';
import Box from '@mui/material/Box';

const colorText = '#000';
const colorBtn = '#048dfc';
const theme = createTheme({
    palette: {
        textColor:colorText
    },
    typography:{
        primary:colorText,
        color:colorText
    }
});
const styleInput = {
    color:colorText,
    borderColor: colorText,
    "& ,input":{
        color:colorText
    },
    "& label.Mui-focused":{
        color:colorText
    },
    "& label":{
        color:colorText,
        borderColor: colorText
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: 'green',
    },
    '& , fieldset': {
        borderColor: colorText
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: colorText
        },
        '&:hover fieldset': {
            borderColor: colorBtn
        },
        '&.Mui-focused fieldset': {
            borderColor: colorBtn
        }
    }
}


const Year = ({setDate,label, minDate, maxDate}) => {

    const [value,setValue] = React.useState("");

    const changeDate = (val) => {
        const Year = Number(new Date(val).getFullYear());
        if(Year !== 'NaN' && Year >= Number(new Date().getFullYear())){
            setDate(Year);
            setValue(val);
        }
    }
 
    return (
        <ThemeProvider theme={theme} >
            <Box sx={{
                "&.MuiYearPicker-root":{
                    color: colorText
                },
                "&.MuiPickersCalendarHeader-root":{
                    color:colorText
                },
                "&,button":{
                    color:colorText
                },
                "&,button>.Mui-selected":{
                    color:colorText
                },
                "&,div":{
                    color:colorText
                },
                ".MuiPickersCalendarHeader-label ":{
                    color:colorText
                }
            }}>
                <DatePicker
                    views={['year']}
                    label={label}
                    value={new Date(value)}
                    disablePast={true}
                    onChange={(e)=>changeDate(e)}
                    renderInput={(params) => (
                        <TextField {...params} helperText={null} sx={styleInput}  />
                    )}
                    minDate={dayjs(minDate)}
                    maxDate={dayjs(maxDate)}
                />
            </Box>
        </ThemeProvider>

    );
};

let today = new Date();
let maxDate = new Date(today.getFullYear() + 4, today.getMonth(), today.getDate());

Year.defaultProps = {
    date:{date:new Date()},
    setDate: ()=>{},
    name:"date",
    label: 'Only years',
    handleChange: () =>{},
    minDate:today,
    maxDate:maxDate
};

Year.propTypes = {
    date: PropTypes.object,
    setDate: PropTypes.func,
    name: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.any,
    handleChange: PropTypes.func,
    minDate: PropTypes.any,
    maxDate: PropTypes.any
};

export default Year;