import { Web3Auth } from '@web3auth/modal';
import { web3Connector } from 'services/web3';
import { ADAPTER_EVENTS } from '@web3auth/base';



export const login = async (blockchain) => {
    const web3auth = new Web3Auth({
        clientId: process.env.REACT_APP_WEB3AUTH_CLIENT_ID,
        chainConfig: {
            chainNamespace: 'eip155',
            chainId: blockchain.chainid,
            rpcTarget: blockchain.rpc
        },
        uiConfig: {
            defaultLanguage: 'en',
            displayErrorsOnModal: true,
            loginMethodsOrder: ['google']
        }
    });
    web3auth.on(ADAPTER_EVENTS.CONNECTING, async () => {
        console.log("connecting");
    });

    web3auth.on(ADAPTER_EVENTS.CONNECTED, async () => {
       // await web3Connector(web3auth.provider);
    });
    web3auth.on(ADAPTER_EVENTS.DISCONNECTED, () => {
        console.log('web3auth disconnected');
    });
    web3auth.on(ADAPTER_EVENTS.ERROR, (error) => {
        console.log('web3auth error', error);
    });
    await web3auth.initModal();
    await web3auth.connect();
    return await web3Connector(web3auth.provider);
};

export const logout = async () => {
    localStorage.clear();
    window.location.href = '/login';
};
