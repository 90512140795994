import axios from "axios";
import { getHeadersAuth } from "./app";

export const uploadThumbnail = (domain , thumbnail, isVideo = false, time = -1) => {
    return new Promise((resolve, reject) => {
        try {
            let type = isVideo ? "video" : "img";
            let url = `${process.env.REACT_APP_API}thumbnail?domain=${domain}&type=${type}&time=${time}`
            axios.post(url, thumbnail, getHeadersAuth()).then((success) => {
                resolve(success.data.url)
            }).catch((err)=> reject(err))
        } catch (error) {
            reject(error)
        }
    })
}


export const uploadBanner = (domain,file) => {
    return new Promise((resolve, reject) => {
        try {
            let url = `${process.env.REACT_APP_API}/extra-files?domain=${domain}`
            axios.post(url, file, getHeadersAuth()).then((success) => {
                resolve(success.data.urls[0])
            }).catch((err)=> reject(err))
        } catch (error) {
            reject(error)
        }
    })
}

export const uploadAsset = uploadBanner;