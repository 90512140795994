import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from "./Success.module.css";
import Transaction from './Transaction';

const Success = ({ icon, title,message, project_key, transaction, explorer }) => {
    const goCollection = () => {
        window.open(`https://${localStorage.getItem('domain')}/collection/${project_key}`)
    }
    return (
        <div style={{textAlign: 'center', padding:'10px 0px 12px 0px', margin:'auto'}}>
            <h3>{title}</h3>
            <FontAwesomeIcon icon={icon} size="2x" color="#00FF00" />
            <h4>{message}</h4>
            { transaction &&
                <Transaction  transaction={transaction} explorer={explorer} />
            }
            { project_key &&
                <button className={styles.successButton} onClick={() => {
                    goCollection()
                }}>
                    <div className={styles.successButtonChild}>
                    </div>
                    <b className={styles.success}>
                        View collection
                    </b>
                </button>
            }
        </div>
    )
}

export default Success;
