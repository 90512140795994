import { useContext, useRef, useState } from "react";
import * as Yup from 'yup';
import { Alert, Box, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, InputLabel, Stack, TextField } from "@mui/material";
import { Field, Form, Formik } from "formik";
import WrappedMaterialTextField from "components/WrappedMaterialTextField";
import { genericDeploy } from "services/blockchain";
import UserContext from "context/userContext";
import WithLoadingBackdropAndErrorSnack from "HOCS/WithLoadingBackdropAndErrorSnack";

export default WithLoadingBackdropAndErrorSnack(function DeployByABI({ setIsLoading, setError }) {
  const { user } = useContext(UserContext);
  const [abi, setAbi] = useState("");
  const [isValidAbi, setIsValidAbi] = useState(false);
  const [lastDeployed, setLastDeployed] = useState({ address: "", viewed: true });

  const handleWriteABI = (event) => {
    handleSetABI(event.target.value);
  }

  const handleSetABI = (txtValue) => {
    setAbi(txtValue);
    if (isValidABI(txtValue.trim())) {
      setIsValidAbi(true);
    } else {
      setIsValidAbi(false);
    }
  }

  const handleLoadByFile = async (file) => {
    const fileContent = await file.text();
    handleSetABI(fileContent);
  }

  const handleDeployContract = async (values) => {
    setError(null);
    setIsLoading(true);
    try {
      const transaction = await genericDeploy(JSON.parse(abi), user.provider, user.wallet, Object.values(values));
      setLastDeployed({ address: transaction._address, viewed: false });
    } catch (error) {
      console.log(error);
      setError("Something went wrong deploying your smart contract");
    } finally {
      setIsLoading(false);
    }
  }

  const isValidABI = (strABI) => {
    try {
      if (!strABI) {
        return false;
      }

      const abi = getAbiFromString(strABI);
      if (!abi) {
        return false;
      }

      const bytecode = JSON.parse(strABI).bytecode;
      if (!bytecode) {
        return false;
      }

      const constructor = abi.find((entry) => entry.type === 'constructor');

      return !(!constructor);
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  const handleCloseDialog = () => {
    setLastDeployed({ ...lastDeployed, viewed: true });
  }

  const handleClear = () => {
    setAbi("");
    setIsValidAbi(false);
  }

  return (
    <Container>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Stack direction="column" spacing={1}>
            <Box>
              <InputLabel htmlFor="contractabi">Contract ABI</InputLabel>
              <TextField
                id="contractabi"
                name="contractabi"
                placeholder="Paste your contract ABI here"
                value={abi}
                multiline
                fullWidth
                minRows={20}
                maxRows={20}
                onChange={handleWriteABI}
              />
            </Box>
            <FileInput
              variant="contained"
              type="button"
              onFileSelect={handleLoadByFile}
              fullWidth
            >
              Load ABI file
            </FileInput>
            <Button variant="outlined" fullWidth onClick={handleClear}>
              Clear
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          {!isValidAbi && !abi && (
            <Alert severity="info">Please provide an ABI</Alert>
          )}
          {!isValidAbi && abi && (
            <Alert severity="warning">Invalid ABI</Alert>
          )}
          {isValidAbi && abi && (
            <FormFromABI abi={getAbiFromString(abi)} onSubmit={handleDeployContract} />
          )}
        </Grid>
      </Grid>
      <DeployedContractDialog
        open={!lastDeployed.viewed}
        onClose={handleCloseDialog}
        contractAddress={lastDeployed.address}
      />
    </Container>
  );
});

const FileInput = ({ onFileSelect, children, ...props }) => {
  const inputRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    onFileSelect(file);
  };

  return (
    <div>
      <input
        ref={inputRef}
        type="file"
        accept=".json"
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
      <Button onClick={() => inputRef.current.click()} {...props}>
        {children}
      </Button>
    </div>
  );
};

const FormFromABI = ({ abi, ...props }) => {
  const abiConstructor = abi.find((entry) => entry.type === 'constructor');

  return (
    <Formik
      validationSchema={Yup.object().shape(getValidationShapeFromConstructor(abiConstructor))}
      initialValues={getInitialValuesFromConstructor(abiConstructor)}
      {...props}
    >
      <FormFromConstructor constructor={abiConstructor} />
    </Formik>
  )
}

const getInitialValuesFromConstructor = (constructor) => {
  const initialValues = {}
  constructor.inputs.forEach(input => {
    initialValues[input.name] = getInitialValueByConstructorInput(input);
  });
  return initialValues;
}

const getInitialValueByConstructorInput = (input) => {
  switch (input.type) {
    case "address":
      return ""
    default:
      return ""
  }
}

const getValidationShapeFromConstructor = (constructor) => {
  const shape = {};
  constructor.inputs.forEach(input => {
    shape[input.name] = getSchemaByConstructorInput(input);
  });
  return shape;
}

const getSchemaByConstructorInput = (input) => {
  switch (input.type) {
    case "address":
      return Yup.string().required("This is a required field").test("address", "Address must be a valid address", (value) => {
        if (!value) return true;
        return value.match(/^0x[a-fA-F0-9]{40}$/);
      });
    default:
      return Yup.string().required("This is a required field");
  }
}

const FormFromConstructor = ({ constructor }) => {
  return (
    <Form>
      <Stack spacing={1}>
        {constructor.inputs.map(input => (
          <Box key={input.name}>
            <InputLabel htmlFor={input.name}>{input.name}</InputLabel>
            <InputFieldByConstructorInput input={input} />
          </Box>
        ))}
        <Button variant="contained" type="submit" fullWidth>Deploy</Button>
      </Stack>
    </Form>
  );
}

const getAbiFromString = (strABI) => {
  try {
    const initABI = JSON.parse(strABI);
    if (Array.isArray(initABI)) {
      return initABI;
    }
    if (Array.isArray(initABI.abi)) {
      return initABI.abi;
    }
    return null
  } catch (error) {
    return null;
  }
}

const InputFieldByConstructorInput = ({ input }) => {
  switch (input.type) {
    case "address":
      return (
        <Field
          name={input.name}
          id={input.name}
          placeholder="0x24FA..."
          fullWidth
          component={WrappedMaterialTextField}
        />
      );
    default:
      return (
        <Field
          name={input.name}
          id={input.name}
          placeholder="0x24FA..."
          fullWidth
          component={WrappedMaterialTextField}
        />
      );
  }
}

const DeployedContractDialog = ({ contractAddress, onClose, ...props }) => {
  return (
    <Dialog
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      onClose={onClose}
      {...props}
    >
      <DialogTitle id="alert-dialog-title">
        {"You have just deployed a new contract!"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <strong>Contract address: </strong> {contractAddress}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}