
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const InputSelect = ({setFieldValue, errors,touched,values,name, title, textAlign, items }) => {
   // console.log('options',items)
    return (
        <div style={{width: '100%', textAlign: textAlign}}>
            <label htmlFor={name}  style={{width: '100%'}}>{title.replace(/^\w/, (c) => c.toUpperCase())}</label>
            <div style={{width: '100%'}}>
                <Select
                as={Select}
                id={name}
                name={name}
                value={values.blockchain}
                error={(errors[name] && touched[name])}
                style={{width: '100%'}}
                onChange={(e) => setFieldValue(name, e.target.value)}>
                    <MenuItem value="0">Select blockchain</MenuItem>
                    {items.map((option) => (
                        <MenuItem key={option.name} value={option.name}>
                            {option.name.toLowerCase().replace(/^\w/, (c) => c.toUpperCase())}
                        </MenuItem>
                    ))}
                </Select>
            </div>
        </div>
    )
}

export default InputSelect;