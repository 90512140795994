import React, { useState } from "react";
import * as Yup from 'yup';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, InputLabel, Switch, TextField, Typography } from "@mui/material";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Success from "./Success";
import Process from "./Process";
import Error from "./Error";
import { addToWhitelistMTX } from "services/whitelist";

const initialValues = {
  whitelist: [{ email: '', mintAmount: 1, notLimited: false }]
}

const validationSchema = Yup.object().shape({
  whitelist: Yup.array().of(Yup.object().shape({
    email: Yup.string().email("Invalid email").required('Required email'),
    mintAmount: Yup.number().min(0, 'Must be greater than or equal to 0'),
    notLimited: Yup.boolean(),
  })).min(1, "You need to register at least one").test('unique-emails', 'Emails must be unique', function (value) {
    return new Set(value.map(item => item.email)).size === value.length;
  }),
});

export default function AddAccountToWhitelistModal({ open, setOpen, collection }) {
  const [transactionStatus, setTransactionStatus] = useState({ status: 'idle', message: '' });

  const handleClose = () => {
    setOpen(false);
    setTransactionStatus({ status: 'idle', message: '' });
  }

  const handleSubmit = async (values) => {
    console.log(values);
    const emails = values.whitelist.map(item => item.email);
    const amounts = values.whitelist.map(item => {
      if (item.notLimited) return -1;
      return item.mintAmount
    });
    console.log(emails);
    console.log(amounts);
    setTransactionStatus({ status: 'pending', message: 'Adding to whitelist...' });
    try {
      await addToWhitelistMTX(collection.domain, collection.blockchain, collection.key, emails, amounts);
      setTransactionStatus({ status: 'success', message: 'Added to whitelist successfully' });
    } catch (error) {
      console.error(error);
      setTransactionStatus({ status: 'error', message: "Couldn't add to whitelist. Please try again later" });
    }
  }

  return (
    <Dialog open={open}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <DialogTitle>Add emails to the whitelist</DialogTitle>
            <DialogContent dividers>
              {transactionStatus.status === 'idle' &&
                <>
                  <InputLabel>Whitelist</InputLabel>
                  <WhitelistFields values={values} setFieldValue={setFieldValue} />
                  <Box sx={{ paddingTop: 2 }}>
                    <ErrorMessage name="whitelist" render={msg => typeof msg === "string" && <Typography variant="body2" color="error">{msg}</Typography>} />
                  </Box>
                </>
              }
              {transactionStatus.status === 'pending' &&
                <Process
                  title="Waiting for confirmation"
                  message={transactionStatus.message} />
              }
              {transactionStatus.status === 'success' &&
                <Success
                  icon={faCheck}
                  title="Success!"
                  message={transactionStatus.message}
                />
              }
              {transactionStatus.status === 'error' &&
                <Error
                  title="Something went wrong"
                  message={transactionStatus.message} />
              }
            </DialogContent>
            <DialogActions>
              <Button disabled={(transactionStatus.status === "pending")} onClick={handleClose}>
                {["success", "error"].includes(transactionStatus.status) ? "Cerrar" : "Cancel"}
              </Button>
              <Button disabled={(transactionStatus.status !== "idle")} type="submit">
                Confirm
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}

const WhitelistFields = ({ values, setFieldValue }) => {
  return (
    <FieldArray
      name="whitelist"
      render={arrayHelpers => (
        <Grid container rowSpacing={3}>
          {values.whitelist && values.whitelist.length > 0 ? (
            values.whitelist.map((_, index) => (
              <Grid item xs={12} key={index}>
                <Grid container key={index} alignItems="flex-start" columnSpacing={2} rowSpacing={1}>
                  <Grid item xs={6}>
                    <Field
                      name={`whitelist.${index}.email`}
                      placeholder="Email"
                      fullWidth
                      component={WrappedMaterialTextField}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Field
                      name={`whitelist.${index}.mintAmount`}
                      placeholder="Amount"
                      type="number"
                      disabled={values.whitelist[index].notLimited}
                      component={WrappedMaterialTextField}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormControlLabel
                      label="No limit"
                      control={
                        <Switch
                          onChange={(_, checked) => {
                            setFieldValue(`whitelist.${index}.notLimited`, checked);
                            setFieldValue(`whitelist.${index}.mintAmount`, '');
                          }}
                        />
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item>
                        <Button
                          type="button"
                          variant="outlined"
                          size="large"
                          startIcon={<DeleteIcon />}
                          onClick={() => arrayHelpers.remove(index)}
                        >
                          Remove
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          type="button"
                          variant="contained"
                          size="large"
                          startIcon={<AddIcon />}
                          onClick={() => arrayHelpers.push({ email: '', mintAmount: 1, notLimited: false })}
                        >
                          Add
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ))
          ) : (
            <Grid item sx={12}>
              <Button
                type="button"
                variant="contained"
                size="large"
                onClick={() => arrayHelpers.push({ email: '', mintAmount: 1, notLimited: false })}
              >
                Add someone to the whitelist
              </Button>
            </Grid>
          )}
        </Grid>
      )}
    />
  );
}

const WrappedMaterialTextField = ({ field, form: { touched, errors }, ...props }) => {
  const keys = field.name.split('.');

  const getTouched = () => {
    let targetValue = touched;
    for (const key of keys) {
      try {
        targetValue = targetValue[key];
      } catch (error) {
        return false;
      }
    }
    return targetValue;
  }

  const getError = () => {
    if (!getTouched()) return "";
    let targetValue = errors;
    for (const key of keys) {
      try {
        targetValue = targetValue[key];
      } catch (error) {
        return "";
      }
    }
    return targetValue;
  }

  return (
    <TextField
      {...field}
      {...props}
      error={getTouched() && getError()}
      helperText={getError()}
    />
  )
}