import React from 'react';
import PropTypes from 'prop-types';
import Year from './components/Year';

const Calendar = ({type,setDate,label,handleChange}) => {
    return (
        <React.Fragment>
            {
                type === 'only-years' &&
                <Year
                    setDate={setDate}
                    label={label}
                    handleChange={handleChange}
                />
            }
        </React.Fragment>
    );
};




Calendar.propTypes = {
    type: PropTypes.string,
    date: PropTypes.object,
    setDate: PropTypes.func,
    name: PropTypes.string,
    label: PropTypes.string,
    handleChange: PropTypes.func
};

export default Calendar;