import { findABIOf } from "services/blockchain";
import styles from "./Address.module.css";
import {
    Tooltip,
  } from 'react-tippy';

function PreviewABI({ contractId }) {
    const copyText = async() => {
        await navigator.clipboard.writeText(JSON.stringify(findABIOf(contractId)));
    }
    return (
        <Tooltip
        title="Copied!"
        position="bottom"
        trigger="click">
            <small style={{cursor: 'pointer', maxWidth: '500px'}}>
                Click ABI to copy <br />
                <b className={styles.walletAddress} onClick={ () => copyText() }>
                    { JSON.stringify(findABIOf(contractId)).substring(0,300) } ...
                </b>
            </small>
        </Tooltip>
    )
}

export default PreviewABI;