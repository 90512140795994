import styles from "./ConfirmDeployCard.module.css";
import { Formik, Form, Field, /* ErrorMessage */ } from 'formik';
import * as Yup from 'yup';
import { currencyValidationSchema } from "./MintPanels/currencyValidationSchema ";
import { useState } from "react";
import validateObjWallet from "./MintPanels/validateObjWallet";

const ConfirmDeployWithSplitAmountUSDC = ({handleConfirm, handlePreview}) => {
  const [count, setCount] = useState([0]);
  const handleSubmit = (values) => {
    handleConfirm(values)
  };

  const handleAddItem = () => {
    if(count.length < 3) {
      setCount([...count, count.length]);
    }
  };

  const handleRemoveItem = () => {
    if(count.length > 1) {
      setCount((prevArray) => prevArray.slice(0, -1));
    }
  };

  return (
    <Formik
    initialValues={{ 
      price: '',
      priceUSDC: '',
      wallets: [
        { wallet: "", percentage: 0 }
      ] }}
    onSubmit={handleSubmit}
    className={styles.sectionconfirm}
    validate={validateObjWallet}
    validationSchema={Yup.object({
      price: currencyValidationSchema,
      priceUSDC: currencyValidationSchema,
    })}>
        {({ errors, touched }) => (
          <Form  className={styles.sectionconfirm}>
            <div className={styles.price}>Price</div>
            <Field className={styles.sectionconfirmChild} name="price" type="number" step="0.01" style={{width:'99%'}}/>
            {errors.price && touched.price && (
              <div className={styles.error}>{errors.price}</div>
            )}
            <div className={styles.price}>Price USDC</div>
            <Field className={styles.sectionconfirmChild} name="priceUSDC" type="number" step="0.01" style={{width:'99%'}}/>
            {errors.priceUSDC && touched.priceUSDC && (
              <div className={styles.error}>{errors.priceUSDC}</div>
            )}
            <div className={styles.price}>
              Wallets
              <small style={{color : 'red'}}>(*max 3)</small>
              <button type="button" onClick={handleAddItem}>+</button>
              <button  type="button" onClick={handleRemoveItem}>-</button>
            </div>
            {count.map((_,index) => (
              <div key={index} className={styles.sectionconfirm} style={{flexDirection: 'row'}}>
                <div style={{width:'65%'}}>
                  <div className={styles.price}>Wallet {index +1}</div>
                  <Field
                    name={`wallets.${index}.wallet`}
                    type="text"
                    placeholder={'Wallet '+(index + 1)}
                    className={styles.sectionconfirmChild}
                    style={{width:'100%'}}
                  />
                  {touched.wallets && touched.wallets[index]?.wallet && errors.wallets && errors.wallets[index]?.wallet && 
                    <div className={styles.error}>{errors.wallets?.[index]?.wallet}</div>
                  }
                </div>
                <div style={{width:'25%'}}>
                  <div className={styles.price}>Percentage {index +1}</div>
                  <Field
                    name={`wallets.${index}.percentage`}
                    type="number" step="1" min="1" max="100"
                    placeholder="Percentage"
                    className={styles.sectionconfirmChild}
                    style={{width:'100%'}}
                  />
                  {touched.wallets && touched.wallets[index]?.percentage && errors.wallets?.[index]?.percentage &&
                  (
                    <div className={styles.error}>{errors.wallets?.[index]?.percentage}</div>
                  )
                  }
                </div>
              </div>
              ))
            }
            {touched.wallets && errors.totalPercentage &&
              <div className={styles.error}>{errors.totalPercentage}</div>
            }
            {touched.wallets && errors.walletDouble &&
              <div className={styles.error}>{errors.walletDouble}</div>
            }
            <div className={styles.deploybuttongroup}>
              <button className={styles.confirmbutton} type="submit">
                <div className={styles.confirm}>Confirm</div>
              </button>
              <button className={styles.confirmbutton1} onClick={ () => { handlePreview(null) }}>
                <div className={styles.confirm}>Reload</div>
              </button>
            </div>
          </Form>
        )}
    </Formik>
  );
};

export default ConfirmDeployWithSplitAmountUSDC;
