import * as Yup from "yup";
import { Formik, Form } from "formik";
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle'
import InputSplitWallet from 'components/InputSplitWallets';
import InputFinalPriceCondition from 'components/InputFinalPriceCondition';
import InputNumber from 'components/InputNumber';
import DialogContent from '@mui/material/DialogContent';
import { Grid } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import styles from "./ModalPriceConfiguration.module.css";
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { create } from "services/nfts";
import { updateProjectKey, find } from "services/collection";
import { contractData, deploy, sign } from "services/blockchain";
import { uploadMetadata } from "services/minter";
import { uploadAsset } from "services/thumbnail";
import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ErrorContext from "context/ErrorContext";
import Process from "components/Process";
import Success from "components/Success";
import Error from "components/Error";
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { handleErrors } from "services/messages";
import Avatar from '@mui/material/Avatar';
import DeleteIcon from '@mui/icons-material/Delete';
import Stack from '@mui/material/Stack';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
}
const stringIsIntegerOrFloat = (value) => /^-?\d+(?:\.\d+)?$/.test(value);
const stringIsMoreThanZero = (value) => {
  const num = parseFloat(value);
  return !isNaN(num) && num > 0;
}

const initialValues = {
    preSalePrice : 0,
    price : 0,
    conditionValue : 0,
    wallets : [
        {
            percentage : '', payee : ''
        }
    ],
}

const validationSchema = Yup.object().shape({
    preSalePrice: Yup.string()
      .test(
        'inputEntry',
        'Invalid value',
        stringIsIntegerOrFloat,
      )
      .test(
        'inputEntry',
        'Must be more than zero',
        stringIsMoreThanZero
      ),
    price: Yup.string().required("Price required")
      .test(
        'inputEntry',
        'Invalid value',
        stringIsIntegerOrFloat,
      )
      .test(
        'inputEntry',
        'Must be more than zero',
        stringIsMoreThanZero
      )})
export default function ModalPriceConfiguration({ collection, open, handleClose, user }) {
    const { domain, key } = useParams();
    const [msg, setMsg] = useState({})
    const { error, setError } = useContext(ErrorContext);
    const navigate = useNavigate();
    const deletePayee = (values,index,setFieldValue) => {
        values.wallets.splice(index,1)
        if(values.wallets.length === 0) {
            values.wallets = [
                {
                    percentage : '', payee : ''
                }
            ]
        }
        console.log('attributes wallets:', values.wallets)
        setFieldValue('wallets',values.wallets)
    }
    const isVideo = (file) => {
        return (file && file.type.startsWith('video/'))
    }

    const onSubmit = async(values, { setSubmitting }) => {
        try {
            if(collection) {
                setMsg({ process : {
                    title: 'Upload your images',
                    message: 'Please wait a  moment. This process depends on tha images sizes'
                }})
                let formImage = new FormData();
                formImage.append("files", collection.asset);
                let image_url = await uploadAsset(formImage)
                let animation_url = null;
                let ipfsGateway = 'https://persea.mypinata.cloud/ipfs/';
                if(collection.isAnimation) {
                    formImage = new FormData();
                    formImage.append("files", collection.animation);
                    animation_url = await uploadAsset(formImage)
                    console.log('animation ::', animation_url)
                }
                let payees = [];
                let percentages = [];
                let attributes = []
                values.wallets.forEach((wallet) => {
                    if(wallet.percentage > 0) {
                        payees.push(wallet.payee)
                        percentages.push((parseInt(""+wallet.percentage) * 100))
                    }
                })
                collection.attributes.forEach((attribute) => {
                    if(attribute.name  && attribute.value) {
                        attributes.push(attribute)
                    }
                })
                setMsg({ process : {
                    title: 'Waiting for signature',
                    message: 'Please ensure that your wallet popup signature is open'
                }})
                let meta = {
                    image: ipfsGateway+image_url,
                    animation_url: (collection.isAnimation) ? ipfsGateway+animation_url : undefined,
                    is_video : isVideo(collection.asset),
                    properties : attributes,
                    levels: [],
                    stats : [],
                    name : collection.name,
                    description: collection.description,
                    categories: [],
                    external_url : '',
                    copies : collection.copies,
                    action : "CREATE_NFT_METADATA"
                }
                setMsg({ process : {
                    title: 'Updating persea',
                    message: 'Please wait a moment',
                }})
                let folderHash = await uploadMetadata(domain,key,meta)
                console.log('resMeta ::',folderHash)
                setMsg({ process : {
                    title: 'Waiting for signature',
                    message: 'Please ensure that your wallet popup signature is open'
                }})
                let folder = ipfsGateway + folderHash+"/"
                if (!values.preSalePrice || values.preSalePrice <= 0) {
                    values.preSalePrice = values.price
                }
                let oldCollection = await find(domain,key)
                let { name , symbol} = contractData(oldCollection.name)
                console.log('name',name)
                let transaction = await deploy(folder, values.preSalePrice,collection.copies,user.wallet,user.provider,5,oldCollection.name,symbol, payees,percentages, values.price, values.conditionValue)
                setMsg({ process : {
                    title: 'Updating persea database with your signature',
                    message: 'Please confirm your signature in your wallet'
                }})
                let {message,signature} = await sign(user.wallet,user.provider,"UPDATE_COLLECTION")
                let nft = {
                    project_key :oldCollection.key,
                    base_uri :folder,
                    thumb_url : meta.image,
                    copies : collection.copies,
                    price : values.preSalePrice,
                    signature : signature,
                    message : message,
                    tags : [],
                    categories : []
                }
                await create(oldCollection.domain,nft)
                await updateProjectKey(oldCollection.domain, oldCollection.blockchain,oldCollection.key, transaction._address, message, signature)
                setMsg({ success : {
                    title: 'Creation successful',
                    message: 'Redirected to home in 4s',
                    key : transaction._address.toLocaleUpperCase(),
                    transaction :'',
                    explorer : ''
                }})

            let interval = setInterval(() => {
                clearInterval(interval)
                navigate("/"+oldCollection.domain+"/home");
                },4000)
            } else {
                console.log('Collection not found',collection)
            }
            console.log(values,setSubmitting,collection)
        } catch (error) {
            setError(handleErrors(error))
        }
    }
return (
    <div>
        <BootstrapDialog
        maxWidth="lg"
/*         onClose={handleClose} */
        aria-labelledby="customized-dialog-title"
        open={open}>
            <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                Sell price configuration
            </BootstrapDialogTitle>
            { msg.process == null  && msg.success == null && !error &&
                        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            className={styles.home}
        >
            {({ values,handleChange, errors, touched, setFieldValue }) => (
                <Form>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <div className={styles.parentcontainer} id="parent">
                                <div style={{width: '100%', textAlign: 'left'}} >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <InputNumber
                                                handleChange = {handleChange}
                                                errors = {errors}
                                                touched = {touched}
                                                name={'preSalePrice'}
                                                title = {'Pre-sale Price'}
                                                textAlign = {'left'}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <InputNumber
                                                handleChange = {handleChange}
                                                errors = {errors}
                                                touched = {touched}
                                                name={'price'}
                                                textAlign = {'left'}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                                { values.preSalePrice > 0 &&
                                    <InputFinalPriceCondition
                                        handleChange = {handleChange}
                                        errors = {errors}
                                        touched = {touched}/>
                                }
                                <InputSplitWallet
                                    setFieldValue = {setFieldValue}
                                    values = {values}
                                    errors = {errors}
                                    touched = {touched}/>
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <Box sx={{ flexGrow: 1 , border:'1px solid', padding:'5px'}}>
                                <h3 className={styles.title}>Configuration resume</h3>
                                <small>NFT price</small>
                                { values.preSalePrice > 0 ?
                                    <Typography variant="h6"align="center" gutterBottom>
                                        Pre-sale price { values.preSalePrice} <br /> Price { values.price}
                                    </Typography> :
                                    <Typography variant="h6"align="center" gutterBottom>
                                    Price { values.price}
                                    </Typography>
                                }
                                <small>Wallets that receive</small>
                                { values.wallets.length > 0 && values.wallets[0].id && values.wallets.map( (wallet,index) => ( wallet.id && wallet.payee &&
                                     <Stack direction="row" spacing={2} alignItems="center" style={{padding : '10px'}} key={wallet.id}>
                                        <Avatar  sx={{ width: 16, height: 16, cursor:'pointer' }} onClick={() => { deletePayee(values,index, setFieldValue) }}>
                                            <DeleteIcon sx={{ width: 16, height: 16 }}/>
                                        </Avatar>
                                        <Typography variant="subtitle2" align="center" gutterBottom >
                                            <small>
                                            {wallet.payee}
                                            -
                                            {wallet.percentage}%
                                            </small>
                                        </Typography>
                                     </Stack>
                                ))

                                }
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button type="submit">
                        Confirm
                    </Button>
                </DialogActions>
                </Form>
        )}
                </Formik>

            }
             <div className={styles.parentcontainer} id="parent">
                {  msg.process && !error &&
        <Process
            title={msg.process.title}
            message={msg.process.message} />
        }
        { msg.success && !error &&
        <>
        <Success
            icon={faCheck}
            title={msg.success.title}
            project_key = {msg.success.project_key}
            message={msg.success.message}
            explorer={msg.success.explorer}
            />
        </>
        }

        { error &&
        <Error
            message = {error.message}
            error={error.status}
            title = { error.title} />
        }

             </div>

        </BootstrapDialog>
    </div>
)
}