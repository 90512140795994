import { TextField } from '@mui/material';
import FormError from 'components/FormError';
import { Field } from "formik";

const InputText = ({ handleChange, errors,touched,multiline, rows,name, textAlign }) => {
    return (
        <div style={{width: '100%', textAlign: textAlign}}>
        <label htmlFor={name}>{name.replace(/^\w/, (c) => c.toUpperCase())}</label>
        { multiline ? (
            <Field
                rows={rows}
                multiline
                id={name}
                type="text"
                name={name}
                placeholder={name}
                style={{width: '100%'}}
                error={(errors[name] && touched[name])}
                onChange={handleChange}
                as={TextField}
            />
            ) : (
                <Field
                id={name}
                type="text"
                name={name}
                placeholder="Name"
                style={{width: '100%'}}
                error={(errors[name] && touched[name])}
                onChange={handleChange}
                as={TextField}
            />
            )
        }
        <FormError errors = {errors} touched = {touched} name={name}/>
    </div>
    )
}

export default InputText;