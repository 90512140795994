import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

const Error = ({ status, title,message, project_key }) => {
    console.log('error ke', project_key)
    return (
        <div style={{color : 'red',textAlign: 'center', padding:'10px 0px 12px 0px', margin:'auto'}}>
            <h2>{status}</h2>
            <h3>{title}</h3>
            <FontAwesomeIcon icon={faTriangleExclamation} size="2x" color="red" />
            <h4>{message}</h4>
            { project_key &&
                <>
                    <h5>If you have problems with your project { project_key }, <br />
                    please contact {process.env.REACT_APP_SUPPORT_EMAIL}</h5>
                    <button onClick={ () => { window.location.reload() }}>Reload now</button>
                </>
            }
        </div>
    )
}

export default Error;
