import { Outlet } from "react-router-dom";
import Topbar from "components/Topbar";
import styles from "./ParentContainer.module.css";

export default function Root() {
  return (
    <div className={styles.parentcontainer} id="parent">
      <Topbar />
      <Outlet />
      <div className={styles.footer}>
        <img className={styles.footerIcon} alt="" src="/footer.svg" />
      </div>
    </div>
  );
}