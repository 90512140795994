import { Button } from "@mui/material";
import Avatar from '@mui/material/Avatar';
import { requestChangeNetwork, requestAddNetwork } from "services/blockchain";

const ButtonBlockchainNetwork = ({item, addNetwork = false}) => {
    const handleErrors = () => {
        if (window.ethereum) {
            if(addNetwork) {
                requestAddNetwork(item)
            } else {
                requestChangeNetwork(item)
            }
        }
    }
    return (
        <Button
        style={{
            backgroundColor: '#3f51b5',
            color: '#fff',
            padding: '10px 20px',
            cursor: 'pointer',
            border: 'none',
            borderRadius: '5px',
            fontSize: '16px',
            fontWeight: 'bold',
            boxShadow: '1px 1px 3px rgba(0, 0, 0, 0.3)',
            width: '100%'
        }}
        onClick={() =>{ handleErrors()}}
        startIcon={<Avatar src={item.icon} />}>
            { addNetwork &&
                'Add network '
            }
            {item.name}
        </Button>
  );
};

export default ButtonBlockchainNetwork;