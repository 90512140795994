import { Input } from '@mui/material';
import FormError from 'components/FormError';
import { Field } from "formik";

const InputNumber = ({ handleChange, errors,touched,name, title, textAlign }) => {
    return (
        <div style={{width: '100%', textAlign: textAlign}}>
        { title ?
            <label htmlFor={name}>{title.replace(/^\w/, (c) => c.toUpperCase())}</label> :
            <label htmlFor={name}>{name.replace(/^\w/, (c) => c.toUpperCase())}</label>
        }
        <Field
            id={name}
            type="number"
            name={name}
            placeholder={name}
            style={{width: '100%'}}
            error={(errors[name] && touched[name])}
            onChange={handleChange}
            as={Input}
        />
        <FormError errors = {errors} touched = {touched} name={name}/>
    </div>
    )
}

export default InputNumber;