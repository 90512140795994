import axios from "axios";
import Web3 from "web3";
import { isAddress } from "./blockchain";
import { getHeadersAuth } from "./app";

const url = process.env.REACT_APP_API+"project"

export const findAllByDomain = (domain) => {
    return axios.get(url+"?domain="+domain, getHeadersAuth()).then(res=>res.data)
}

export const findAllByCreator = async(domain, wallet) => {
    return axios.get(url+"?domain="+domain+"&creator="+wallet+"&order=created&limit=30&page=0",getHeadersAuth()).then((res) => {
        const formatedCollections = res.data.map((collection) => formatCollection(collection));
        return formatedCollections;
    })
}

export const find = async(domain, key) => {
    return axios.get(url+"?domain="+domain+ "&address="+key+"&order=created&limit=30&page=0",getHeadersAuth()).then((res) => {
        const collections = res.data.map((collection) => formatCollection(collection));
        return collections[0]
    })
}

const formatCollection = (collection) => {
    const extraDetails = (collection.extra_details) ? collection.extra_details : "{}"
    let status = {}
    if(!isAddress(collection.project_key) && collection.collection_of > 0) {
        status = {
            name : 'Pre-mint confirm',
            background: '#357a38',
            color: '#fff',
            type: 1,
        }
    } else if(isAddress(collection.project_key) && collection.collection_of > 0) {
        status = {
            name : 'Deployed NFTs',
            background: '#357a38',
            color: '#fff',
            type: 2
        }
    } else {
        status = {
            name : 'Empty',
            background: '#ff9100',
            color: '#fff',
            type : 0,
        }
    }
    return {
        name: collection.name,
        description: collection.description,
        thumb_url: collection.thumb_url,
        banner_url: collection.banner_url,
        key: collection.project_key,
        domain: collection.domain,
        blockchain: collection.blockchain.blockchain_name,
        explorer: collection.blockchain.explorer,
        status : status,
        extraDetails: JSON.parse(extraDetails),
        nfts: collection.collection_of,
        created : dateToFormattedDate(collection.created)
    }
}

export const dateToFormattedDate = (time) => {
    const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: true // Use a 12-hour clock (false for 24-hour clock)
      };
      let format = new Date(time* 1000).toLocaleString('es-MX', options);
      return format
}

export const findAllByCreatorEmpty = async(domain, wallet) => {
    let collections = await findAllByCreator(domain,wallet)
    let emptyCollections =  []
    for (let element of collections) {
        if(element.nfts === 0 && !isAddress(element.project_key)) {
            emptyCollections.push(element)
        }
    }
    return emptyCollections
}

export const findAllWithNFTs = async(domain, wallet) => {
    let collections = await findAllByCreator(domain,wallet)
    let withNFTs =  []
    for (let element of collections) {
        if(element.nfts > 0 || isAddress(element.project_key)) {
            withNFTs.push(element)
        }
    }
    return withNFTs
}

export const create = (collection) => {
    return axios.post(`${process.env.REACT_APP_API}/project?domain=${collection.domain}`,collection, getHeadersAuth())
    .then((success) => { return success })
}

export const updateProjectKey =async(domain, blockchain, project_key,new_project_key,message,signature) => {
    let data = {
        project_key : project_key,
        new_project_key : new_project_key,
        message : message,
        signature : signature,
        domain : domain,
        blockchain_name : blockchain
    }
    return axios.put(`${process.env.REACT_APP_API}/project?domain=${domain}`,data,getHeadersAuth())
    .then(res=>res.data)
}


export const updateExtraDetails =async(domain, blockchain, project_key,extra_details,message,signature) => {
    let data = {
        project_key : project_key,
        extra_details : extra_details,
        message : message,
        signature : signature,
        domain : domain,
        blockchain_name : blockchain
    }
    return axios.put(`${process.env.REACT_APP_API}/project?domain=${domain}`,data,getHeadersAuth())
    .then(res=>res.data)
}

export const update = async (domain, project_key, collection, message, signature) => {
    let data = {
        project_key: project_key,
        banner_url: collection.banner_url,
        thumb_url: collection.thumb_url,
        name: collection.name,
        description: collection.description,
        is_video: collection.is_video,
        message: message,
        signature: signature,
        domain: domain,
        blockchain_name : collection.blockchain_name,
    }
    return axios.put(`${process.env.REACT_APP_API}/project?domain=${domain}`,data,getHeadersAuth())
    .then(res=>res.data)
}

export const isProjectMinted = (project_key) => {
    const web3 = new Web3();
    const minted = web3.utils.checkAddressChecksum(project_key);
    return minted;
}