import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { createTheme, adaptV4Theme } from '@mui/material/styles';
const classes = createTheme(adaptV4Theme({
  icon: {
    fontSize: '6rem',
    color: 'gray',
  },
  header: {
    color: 'gray',
  },
  paragraph: {
    color: 'gray',
  },
}));

function NotFoundPage() {


  return (
    <>
       <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh', gap: '1rem' }}>
      <Grid item>
        <Typography className={classes.header} variant="h1">Page Not Found</Typography>
        <Typography className={classes.paragraph} variant="body1">Sorry, we can't seem to find the page you're looking for.</Typography>
      </Grid>
      </Grid>
    </>
  );
}

export default NotFoundPage;