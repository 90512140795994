import styles from "./SectionPreviewLinksDiv.module.css";
import { Formik, Form, Field, /* ErrorMessage */ } from 'formik';
import * as Yup from 'yup';
import { urlValidationSchema } from "./MintPanels/urlValidationSchema";

const SectionPreviewLinksDiv = ({handlePreview}) => {

  const handleSubmit = (values) => {
    console.log(`Submitting form with URLs: ${values.doc} and ${values.folder}`);
    handlePreview(values);
    // You can perform further actions with the URLs here, such as making API requests
  };

  return (
    <Formik
    initialValues={{ folder: '', doc: '' }}
    onSubmit={handleSubmit}
    className={styles.sectionpreviewlinks}
    validationSchema={Yup.object({
      folder: urlValidationSchema,
      doc: urlValidationSchema
    })}>
      {({ errors, touched }) => (
        <Form  className={styles.sectionpreviewlinks}>
          <h3 className={styles.csvLink}>csv link</h3>
          <Field  className={styles.sectionpreviewlinksChild} name="doc" type="text" />
          {errors.doc && touched.doc && (
              <div className={styles.error}>{errors.doc}</div>
            )}
          <h3 className={styles.csvLink}>Images folder link</h3>
          <Field  className={styles.sectionpreviewlinksItem} name="folder" type="text" />
          {errors.folder && touched.folder && (
              <div className={styles.error}>{errors.folder}</div>
            )}
          <button className={styles.previewbutton} type="submit">
            <div className={styles.preview}>Preview</div>
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default SectionPreviewLinksDiv;
