
import ErrorContext from "context/ErrorContext";
import { useContext, useEffect, useState } from "react";
import { handleErrors } from "services/messages";
import { findAll } from "services/minter";
import styles from "./MintNFTPreview.module.css";
import NFTCard from "./NFTCard";

const MintNFTPreview = ({ collection }) => {
    const [ nfts , setNFTS] = useState([])
    const { setError } = useContext(ErrorContext);
    const [value, setValue] = useState(0);
    const [value2, setValue2] = useState(30);
    const [splice, setSplice] = useState(0);
    const [splice2, setSplice2] = useState(30);
    const handleInputChange = (event) => {
        setValue(event.target.value);
        if(value2 > event.target.value) {
            setSplice(event.target.value);

        }
    }

    const handleInputChange2 = (event) => {
        setValue2(event.target.value);
        if(value < event.target.value) {
            setSplice2(event.target.value);
        }
      }
    useEffect(() => {
        const getNFTS = async() =>{
            try {
                if(collection && collection.domain) {
                    let nfts = await findAll(collection.domain,collection.key)
                    setNFTS(nfts)
                }
            } catch (error) {
                setError(handleErrors(error))
            }
        }
        getNFTS()
      },[collection, setError]);

    if(!nfts || nfts.length === 0) {
        return (
            <h2>Try to mint nfts to view something here</h2>
        )
    }
    return (
        <>
        <h3 className={styles.youViewFrom}>
          You view from <input type={'number'} value={value} onChange={handleInputChange} min='0'/> to <input type={'number'} value={value2} onChange={handleInputChange2} min='30'/> and the total is {nfts.length}
        </h3>
        <div className={styles.containerNFTs} style={
                    { display: "grid", 
                    gridTemplateColumns: "repeat(4, 1fr)" ,
                    gridTemplateRows: "repeat(auto-fit, minmax(1fr, 1fr))",
                    }}>
            { nfts.slice(splice,splice2).map((nft) => {
                return <NFTCard nft = {nft} key={nft.transaction} />;
            })
            }
        </div>
        </>
    )

}

export default MintNFTPreview;