import styles from "./ConfirmDeployCard.module.css";
import { Formik, Form, Field, /* ErrorMessage */ } from 'formik';
import * as Yup from 'yup';
import { currencyValidationSchema } from "./MintPanels/currencyValidationSchema ";

const ConfirmDeployCard = ({handleConfirm, handlePreview}) => {
  const handleSubmit = (values) => {
    console.log(`Submitting form with URLs: ${values.doc} and ${values.folder}`);
    handleConfirm(values)
  };

  return (
    <Formik
    initialValues={{ price: '' }}
    onSubmit={handleSubmit}
    className={styles.sectionconfirm}
    validationSchema={Yup.object({
      price: currencyValidationSchema
    })}>
        {({ errors, touched }) => (
          <Form  className={styles.sectionconfirm}>
            <div className={styles.price}>Price</div>
              <Field className={styles.sectionconfirmChild} name="price" type="number" step="0.01"/>
              {errors.price && touched.price && (
                <div className={styles.error}>{errors.price}</div>
              )}
              <div className={styles.deploybuttongroup} type="submit">
                <button className={styles.confirmbutton}>
                  <div className={styles.confirm}>Confirm</div>
                </button>
                <button className={styles.confirmbutton1} onClick={ () => { handlePreview(null) }}>
                  <div className={styles.confirm}>Reload</div>
                </button>
              </div>
          </Form>
        )}
    </Formik>
  );
};

export default ConfirmDeployCard;
