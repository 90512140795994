import * as Yup from 'yup';

export const urlValidationSchema = Yup.string()/* .test(
    'valid-url',
    'Error: Please enter a valid Google Drive folder or document URL',
    validateUrl
  ) */;

/*   function validateUrl(url) {
    // Define regular expression pattern for a Google Sheets or Drive URL
    const urlPattern = /^(https?:\/\/)(docs\.google\.com\/spreadsheets\/d\/|drive\.google\.com\/drive\/u\/\d\/folders\/)([\w-]+)\/?/;
    if (!urlPattern.test(url)) {
      return 'Invalid Google Sheets or Drive URL';
    }
    return undefined; // Return undefined if URL is valid
  } */