import styles from "./Button.module.css";

function Button({ text, handler}) {
    return (
        <button className={styles.connectWalletButton} onClick={ ()=> { handler() }}>
            <div className={styles.connectWalletButtonChild} />
            <b className={styles.connectWallet}>{text}</b>
        </button>
    )
}

export default Button;
