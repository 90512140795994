import {  Box, Grid } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
const PreviewAttributes = ({ setFieldValue,attributes }) => {
    const remove = (index) => {
        attributes.splice(index,1)
        if(attributes.length === 0) {
            attributes = [
                {
                    value : '', name : ''
                }
            ]
        }
        console.log('attributes removed:', attributes)
        setFieldValue('attributes',attributes)
    }
    return (
        <Grid container spacing={2}  style={{textAlign : 'center'}}>
            { attributes.length > 0 && attributes[0].id && attributes.map( (attribute,index) => (
                    <Grid item xs={12} md={3} key={attribute.id}>
                        <Box sx={{ border: '1px solid blue', p: 2 }}>
                            <small  style={{width : '100%'}}>
                                <b>Name</b> <br /> {attribute.name} <br /> <b>Value</b> <br /> {attribute.value}
                            </small> <br />
                            <IconButton color="primary" component="label" onClick={() => { remove(index)}}>
                                <DeleteIcon />
                            </IconButton>
                        </Box>
                    </Grid>
                ))
            }
        </Grid>
    )
}

export default PreviewAttributes;