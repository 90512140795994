const FormError = ({ errors,touched,name}) => {
    return (
        errors[name] && touched[name] && (
            <div style={{
                    color: '#d32f2f',
                    fontSize: '16px'
                  }}>{errors[name]}</div>
        )
    )
}

export default FormError;