import Web3 from 'web3';

const ethereum = (provider) => new Web3(provider);

export const web3Connector = async (provider) => {
    const web3 = ethereum(provider);
    const accounts = await web3.eth.getAccounts();
    const balance = await web3.eth.getBalance(accounts[0]);
    const user = {
        wallet: accounts[0],
        balance,
        provider: provider
    };
    return user;
};

export const sign = async ( from, provider, message = "CREATE_COLLECTION") => {
    const signature = await ethereum(provider).eth.personal.sign(message, from);
    return signature;
};

