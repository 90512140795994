import SidebarCollectionInfo from "components/SidebarCollectionInfo";
import Success from "components/Success";
import SwitchMintTypePanel from "components/SwitchMintTypePanel";
import UserContext from "context/userContext";
import { useContext, useEffect, useState } from "react";
import { find, updateProjectKey } from "services/collection";
import styles from "./BatchMintPanel.module.css";
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import Process from "components/Process";
import MintNFTPreview from "components/MintNFTPreview";
import ButtonCancel from "components/ButtonCancel";
import { findAll, validateDocAndFolder, validateWeeksOfTheYear } from "services/minter";
import { contractData, deploy, sign, MINT_TYPES } from "services/blockchain";
import ErrorContext from "context/ErrorContext";
import Error from "components/Error";
import { handleErrors } from "services/messages";
import { useParams } from "react-router-dom";

const BatchMintPanel = () => {

  const { domain, projectKey, blockchain, mintTypeId } = useParams();
  const { error , setError } = useContext(ErrorContext);
  const { user} = useContext(UserContext);
  const [collection ,setCollection] =  useState([])
  const [mintStatus ,setMintStatus] =  useState(0)
  const [mintInfo ,setMintInfo] =  useState(0)
  const [msg, setMsg] = useState({})

  useEffect(() => {
    const getCollection = async() =>{
      if(user && domain) {
        try {
          let newCollections = await find(domain,projectKey)
          let nfts = await findAll(domain,projectKey)
          if(nfts.length > 0) {
            setMintStatus(2)
          }
          setCollection(newCollections)
        } catch (error) {
          setError(handleErrors(error))
          setMsg({})
        }
      }
    }
    getCollection()
  },[user, domain, projectKey, setError]);

  const handlePreview = async(values) => {
    try {
      setMintStatus(5)
      setMsg({ process : {
        title: 'Waiting for signature',
        message: 'Please ensure that your wallet popup signature is open'
      }})
      let {signature , message} = await sign(user.wallet,user.provider)
      setMsg({ process : {
        title: 'Validating doc and folder',
        message: 'This will take some time, not close this window, go get a coffee please!'
      }})
      let data = null;
      if (Number(mintTypeId) === MINT_TYPES.mintWeeksGasless) {
        data = {
          year: values.year,
          contract_address: collection.key,
          blockchain_name: collection.blockchain,
          message: message,
          signature: signature
        }
      } else if(Number(mintTypeId) === MINT_TYPES.mintWithPriceAndPayees) {
        data = null;
        console.log('Values to mint', values)
        await handleConfirm(values)
      } else if (!values) {
        console.log('use saved data', mintInfo)
        data = prepareDataToValidate(mintInfo, signature, message)
      } else {
        data = prepareDataToValidate(values, signature, message)
        setMintInfo(values)
      }

      if(data) {
        let total = 1000;
        try {
          if (Number(mintTypeId) === MINT_TYPES.mintWeeksGasless) {
            await validateWeeksOfTheYear(domain, data);
            total = 52;
          } else {
            total = await validateDocAndFolder(domain, collection.key,data)
          }
          setMintStatus(2)
        } catch (error) {
          console.log('error',error)
          if (error.code === 'ECONNABORTED' || error.code === 'ERR_NETWORK') {
            let interval =  setInterval(async() =>{
              try {
                let nfts = await findAll(domain,projectKey)
                if(total !== 0 && nfts.length > 0 && nfts.length >= total) {
                  setMintStatus(2)
                  clearInterval(interval)
                }
              } catch (error) {
                clearInterval(interval)
                setError(handleErrors(error))
                setMsg({})
              }
            }, 5000)
          } else {
            setError(handleErrors(error))
            setMsg({})
          }
        }
      }
    } catch (error) {
      setError(handleErrors(error))
      setMsg({})
    }
  }

  const prepareDataToValidate = (values, signature, message) => {
    return {
      sheet_uri: values.doc,
      folder_uri: values.folder,
      signature: signature,
      message: message,
      blockchain_name:blockchain
    }
  }

  const handleConfirm = async(
    values,
    callbackBefore = async () => {},
    callbackAfter = async () => {}
  ) => {
    console.log('Confirm',values);
    try {
      console.log('Confirm',values)
      setMintStatus(5)
      setMsg({ process : {
        title: 'Deploying your collection to the blockchain',
        message: 'Please confirm with your wallet '
      }})

      await callbackBefore();

      let nfts = await findAll(collection.domain,collection.key)
      if((nfts.length > 0  && nfts[0].metadata_url) || Number(mintTypeId) === MINT_TYPES.mintWithPriceAndPayees) {
        let {name, symbol} = contractData(collection.name)
        let payees = []
        let shares = []
        let priceUSDC = 0;
        let folder = "https://persea.mypinata.cloud/ipfs/"+nfts[0]?.metadata_url.split('/')[4]
        if(parseInt(mintTypeId) === 2) {
          values.price = 0;
        }
        console.log('values ::', values)
        if(parseInt(mintTypeId) === 3 || parseInt(mintTypeId) === 4 || parseInt(mintTypeId) === MINT_TYPES.mintWithPriceAndPayees) {
          values.wallets.forEach(element => {
            payees.push(element.wallet)
            shares.push(element.percentage * 100)
          });
        }

        if(parseInt(mintTypeId) === MINT_TYPES.mintWithPriceAndPayees) {
          values.contractURI = "";
        }

        if(parseInt(mintTypeId) === 4) {
          priceUSDC = values.priceUSDC
        }

        if(parseInt(mintTypeId) === 6) {}

        let transaction = await deploy(
          folder+"/",
          values.price,
          nfts?.length,
          user.wallet,
          user.provider,
          mintTypeId,
          name,
          symbol,
          payees,
          shares,
          0,
          0,
          priceUSDC,
          values.version,
          values.contractURI,
          values.reservedNFTS,
          values.claimants,
          values.claimantsConf,
          process.env.REACT_APP_ARWEAVE_GATEGAY
        )
        setMsg({ process : {
          title: 'Updating persea database with your signature',
          message: 'Please confirm your signature in your wallet'
        }})
        let { signature , message } = await sign(user.wallet,user.provider,"UPDATE_COLLECTION")
        setMsg({ process : {
          title: 'Updating persea',
          message: 'Please wait a moment',
        }})
        await updateProjectKey(collection.domain, collection.blockchain,collection.key, transaction._address, message, signature)
        setMintStatus(4)

        await callbackAfter();

        setMsg({ success : {
          title: 'Deploy successful',
          message: 'The contract was deployed successfully',
          key :  transaction._address.toUpperCase(),
          transaction : transaction,
          explorer : collection.explorer
        }})
      }
    } catch (error) {
      setError(handleErrors(error))
      setMsg({})
    }
  }

  return ( user && collection &&
    <div className={styles.batchmintpanel}>
      <div className={styles.sidebarminter}>
        <SidebarCollectionInfo collection={collection} />
        { mintStatus < 4 && !error &&
          <>
            <SwitchMintTypePanel
              mintStatus={mintStatus}
              mintType={mintTypeId}
              handlePreview={handlePreview}
              handleConfirm={handleConfirm} />
            <ButtonCancel config = {{domain, blockchain}}/>
          </>
        }
        { mintStatus === 5 && msg.process && !error &&
          <Process
            title={msg.process.title}
            message={msg.process.message} />
        }
        { mintStatus ===  4 && msg.success && !error &&
          <Success
            icon={faCheck}
            title={msg.success.title}
            message={msg.success.message}
            project_key={msg.success.key}
            transaction={msg.success.transaction}
            explorer={msg.success.explorer}
            />
        }
        { error && projectKey &&
          <Error
            message = {error.message}
            error={error.status}
            title = { error.title}
            project_key = {projectKey}/>
        }
      </div>
      <div className={styles.sectionpreviewnft}>
        { mintStatus === 5 && msg.process && !error &&
          <Process
            title={msg.process.title}
            message={msg.process.message} />
        }
        { mintStatus ===  4 && msg.success && !error &&
          <Success
            icon={faCheck}
            title={msg.success.title}
            message={msg.success.message}
            project_key={msg.success.key}
            transaction={msg.success.transaction}
            explorer={msg.success.explorer}
            />
        }
        { mintStatus < 4 && !error && collection &&
          <MintNFTPreview  collection= {collection}/>
        }
        { error && projectKey &&
          <Error
            message = {error.message}
            error={error.status}
            title = { error.title}
            project_key = { projectKey }/>
        }
      </div>
    </div>
  )
}

export default BatchMintPanel;