import styles from "./SidebarCollectionInfo.module.css";


const SidebarCollectionInfo = ({collection})  => {
    return ( collection &&
        <>
            <div className={styles.collectionname}>
            <img
                className={styles.imageAsset1Icon}
                alt=""
                src={collection.thumb_url}
            />
            </div>
            <h3 className={styles.name}>
            <div className={styles.coleccinName}>{ collection.name }</div>
            </h3>
        </>
    )
}
export default SidebarCollectionInfo;
