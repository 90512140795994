const validateObjWallet = (values) => {
  const errors = {};
 console.log('values ::',values)
  // Check each object in the array
  let wallets = {}
  let totalPercentage = 0;
  errors['wallets'] = []
  values.wallets.forEach((obj, index) => {
    const { wallet, percentage } = obj;
    if (!wallet) {
        errors['wallets'][index] = { wallet : "Required"}
    } else if (!/^(0x)?[0-9a-fA-F]{40}$/i.test(wallet)) {
      errors[`wallets[${index}].wallet`] = "Invalid Ethereum address";
    }

    // Check percentage
    if (!percentage) {
        errors['wallets'][index] = { percentage : "Required"}
    } else if (isNaN(Number(percentage))) {
        errors[`wallets[${index}].percentage`] = "Percentage must be a number";
    } else if (Number(percentage) < 0 || Number(percentage) > 100) {
        errors[`wallets[${index}].percentage`] = "Percentage must be between 0 and 100";
    }
    totalPercentage += percentage;
    if(totalPercentage > 100 || totalPercentage < 100) {
        errors[`totalPercentage`] = "Percentage must be a total of 100";
    } else {
        if(errors[`totalPercentage`]) {
            delete errors[`totalPercentage`];
        }
    }
    if(!wallets[wallet]){
        wallets[wallet] = true;
    } else {
        errors[`walletDouble`] = "This wallet is already in use";
    }
});
    if(errors['wallets'].length === 0) {
        return {}
    }
console.log('errors',errors);
  return errors;
};

export default validateObjWallet;