import styles from "./SectionPreviewLinksDiv.module.css";
import { Formik, Form, Field, FieldArray } from 'formik';
import * as Yup from 'yup';
import WrappedMaterialTextField from "./WrappedMaterialTextField";
import { Box, Button, Stack } from "@mui/material";

const validationSchema = Yup.object({
  price: Yup.number().required('Price is required').min(0),
  wallets: Yup.array().of(Yup.object().shape({
    wallet: Yup.string().required('Address is required'),
    percentage: Yup.number().required('Swallethares is required').min(0),
  })).min(1, "You need to register at least one").test('unique-addresses', 'Addresses must be unique', function (value) {
    return new Set(value.map(item => item.wallet)).size === value.length;
  }),
});

export default function SectionPreviewPriceAndPayees({ handlePreview }) {

  const handleSubmit = (values) => {
    handlePreview(values);
  };

  return (
    <Formik
      initialValues={{ price: 0, wallets: [{ wallet: "", percentage: 0 }] }}
      onSubmit={handleSubmit}
      className={styles.sectionpreviewlinks}
      validationSchema={validationSchema}
    >
      {({ values }) => (
        <Form className={styles.sectionpreviewlinks} style={{ alignItems: "center" }}>
          <Stack spacing={3} width="75%">
            <Field
              name="price"
              fullWidth
              component={WrappedMaterialTextField}
              label="Price"
              type="number"
              placeholder="Enter price"
            />
            <PayeesFields values={values} />
          </Stack>
          <button className={styles.previewbutton} type="submit">
            <div className={styles.preview}>Preview</div>
          </button>
        </Form>
      )}
    </Formik>
  );
};

const PayeesFields = ({ values }) => {
  return (
    <FieldArray name="wallets"
      render={arrayHelpers => (
        <Stack spacing={3}>
          {values.wallets && values.wallets.length > 0 ? (
            values.wallets.map((_, index) => (
              <Stack key={index} spacing={1}>
                <Field
                  name={`wallets.${index}.wallet`}
                  fullWidth
                  component={WrappedMaterialTextField}
                  label="Address"
                  type="text"
                  placeholder="Enter wallet"
                />
                <Field
                  name={`wallets.${index}.percentage`}
                  fullWidth
                  component={WrappedMaterialTextField}
                  label="Percentages"
                  type="number"
                  placeholder="Enter percentage"
                />
                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 1 }}>
                  <Button
                    variant="outlined"
                    type="button"
                    fullWidth
                    onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                  >
                    -
                  </Button>
                  <Button
                    variant="contained"
                    type="button"
                    fullWidth
                    onClick={() => arrayHelpers.insert(index + 1, { wallet: "", percentage: '' })}
                  >
                    +
                  </Button>
                </Box>
              </Stack>
            ))
          ) : (
            <Button type="button" onClick={() => arrayHelpers.push({ wallet: '', percentage: '' })}>
              Add a payee
            </Button>
          )}
        </Stack>
      )}
    />
  );
}