import { useNavigate } from "react-router-dom";
import styles from "./MintType.module.css";

const MintType = ({ mintType, collection }) => {
  const navigate = useNavigate();
  const gotToMint = () => {
    let url = "/" + collection.domain + "/mint/" + collection.key + "/" + mintType.id
    navigate(url);

  }
  return (
    <div className={styles.cardTypeOfMint} >
      <h3 className={styles.mintBatchAndContainer}>
        <p className={styles.mintBatchAnd}>{mintType.name}</p>
      </h3>
      <div className={styles.sectiondescription}>
        <blockquote className={styles.description}>
          <blockquote
            className={styles.loremIpsumIs}
          >{mintType.description}</blockquote>
        </blockquote>
        <button className={styles.useminttypebutton} onClick={() => { gotToMint() }} disabled={!mintType.active}>
          <div className={styles.rectangleParent}>
            <div className={styles.groupChild} />
            <b className={styles.use}>Use</b>
          </div>
        </button>
      </div>
    </div>
  )
}

export default MintType;
