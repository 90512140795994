import styles from "./ConfirmDeployCard.module.css";
import { Formik, Form, /* ErrorMessage */ } from 'formik';

const ConfirmDeployCardWithoutSell = ({handleConfirm, handlePreview}) => {
  const handleSubmit = () => {
    handleConfirm({})
  };

  return (
    <Formik
    onSubmit={handleSubmit}
    initialValues={{}}
    className={styles.sectionconfirm}>
        {() => (
          <Form  className={styles.sectionconfirm}>
            <div className={styles.deploybuttongroup} type="submit">
              <button className={styles.confirmbutton}>
                <div className={styles.confirm}>Confirm</div>
              </button>
              <button className={styles.confirmbutton1} onClick={ () => { handlePreview(null) }}>
                <div className={styles.confirm}>Reload</div>
              </button>
            </div>
          </Form>
        )}
    </Formik>
  );
};

export default ConfirmDeployCardWithoutSell;
