import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { findAllByDomain } from "services/blockchain";
import ButtonBlockchainNetwork from "./ButtonBlockchainNetwork";

const PanelAddNetwork = () => {
    const { domain } = useParams();
    const [blockchains, setBlockchains] = useState({ isLoading: true, data: [] });
    useEffect(() => {
        let ignore = false;
        async function fetchBlockchains() {
            if (!ignore) {
              const result = await findAllByDomain(domain,false);
              setBlockchains({ isLoading: false, data: result })
            }
        }
        fetchBlockchains()
    }, [domain])
  return (
    <>
        <h4>Blockchain networks available in your instance</h4>
        <Grid container spacing={2} justify="center" alignItems="center">
            {blockchains.data.map((item,index) => (
                <Grid item xs={4} key={index+'_'+item.chainid}>
                    <ButtonBlockchainNetwork
                        addNetwork={true}
                        item = {item}/>
                </Grid>
            ))}
        </Grid>
    </>
  );
};

export default PanelAddNetwork;
