import {
  Routes,
  Route,
  useNavigationType,
  useLocation
} from "react-router-dom";
import ContainerCollections from "./pages/home/ContainerCollections";
import { useEffect, useMemo, useState } from "react";
import UserContext from "./context/userContext";
import SelectMintType from "pages/SelectMintType/SelectMintType";
import BatchMintPanel from "pages/BatchMintPanel/BatchMintPanel";
import CreateCollection from "pages/CreateCollection/CreateCollection";
import ContainerAnalytics from "pages/Analytics/ContainerAnalytics";
import ErrorContext from "context/ErrorContext";
import MintOne from "pages/MintOne/MintOne";
import CardPayments from "pages/CardPayments/CardPayments";
import EditCollection from "pages/EditCollection/EditCollection";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Root from "pages/Root";
import NotFoundPage from "components/NotFound";
import PanelAddNetwork from "components/PanelAddNetwork";
import DeployByABI from "pages/DeployByABI";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;
  const [user, setUser] = useState(null);
  const value = useMemo(() => {
    return { user, setUser };
  }, [user, setUser]);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  return (
    <ErrorContext.Provider value={{ error, setError }}>
        <LocalizationProvider  dateAdapter={AdapterDayjs} adapterLocale="en">
        <UserContext.Provider value={value}>
            <Routes>
                <Route exact path="/:domain"  element={<Root />} >
                    <Route index element={<PanelAddNetwork />} />
                    <Route path="home" element={<ContainerCollections />} />
                    <Route path="create" element={<CreateCollection />} />
                    <Route path="edit/:projectKey" element={<EditCollection />} />
                    <Route path="collection/:projectKey" element={<h1>Ver collección</h1>} />
                    <Route path="select-mint-type/:projectKey/:blockchain" element={<SelectMintType />} />
                    <Route path="mint-one/:key/:mint_type_id" element={<MintOne />} />
                    <Route path="mint/:projectKey/:mintTypeId" element={<BatchMintPanel />} />
                    <Route path="deploy-by-abi/" element={<DeployByABI />} />
                    <Route path="analytics/:projectKey" element={<ContainerAnalytics />} />
                    <Route path="card-payments/:projectKey" element={<CardPayments />} />
                    <Route path="*" element={<NotFoundPage />} />
                </Route>
                <Route path="*" element={<NotFoundPage />} />
            </Routes>
        </UserContext.Provider>
        </LocalizationProvider>
    </ErrorContext.Provider>
  );
}
export default App;
