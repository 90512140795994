import * as React from 'react';
import ModalSettings from "./ModalSettings";
import { useNavigate } from "react-router-dom";
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CardActions from '@mui/material/CardActions';
import ImageIcon from '@mui/icons-material/Image';
import PreviewIcon from '@mui/icons-material/Preview';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';


const CollectionCard = ({ collection }) => {
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate();

    const handleClose = () => {
        setOpen(false);
    };

    const goToCollection = () => {
        let url = `/${collection.domain}/collection/${collection.key}`;
        navigate(url);
    }

    return (
        <Card sx={{ maxWidth: 380 }}>
            <CardHeader
                action={
                <IconButton aria-label="settings" onClick ={ () => setOpen(true)}>
                    <MoreVertIcon />
                </IconButton>
                }
                title={collection.name.substring(0,100)}
                subheader={collection.created}
            />
            <CardMedia
                component={ collection.is_video  ? "img" : "img"}
                height="345"
                src={ collection.thumb_url}
                alt={collection.name}
            />
            <Box
                    sx={{
                        display:'flex',
                        justifyContent: 'center',
                        alignItems: 'flex-end',
                       // transform: 'rotate(-45deg)',
                        background: collection.status.background,
                        color: collection.status.color,
                        fontSize:'20px',
                        padding:'5px',
                        fontWeight:'600',
                        fontFamily:'BentonSansBBVA-Medium,sans-serif'
                    }}
                >
                { collection.status.name}
            </Box>

            <CardActions disableSpacing>
                <IconButton onClick={() => window.open(collection.thumb_url,'_blank')}>
                    <ImageIcon />
                </IconButton>
                { collection.nfts > 0 &&
                    <IconButton onClick={() => goToCollection()}>
                        <PreviewIcon  />
                    </IconButton>
                }
                { collection.status.type === 2 &&
                    <Button size="small" color="primary" onClick={() => window.open(collection.explorer+"/address/"+collection.key.toLowerCase(),'_blank')}>
                        View in explorer
                    </Button>
                }
            </CardActions>
            <ModalSettings collection = {collection} open = {open}  handleClose={handleClose} />
        </Card>
    );
};

export default CollectionCard;

