import { useNavigate } from "react-router-dom";
import styles from "./ButtonCancel.module.css";

function ButtonCancel({config}) {
    const navigate = useNavigate();
    const cancel = () => {
        navigate("/home/"+config.domain+"/"+config.blockchain);
    }
    return (
        <button className={styles.connectWalletButton} onClick={ ()=> { cancel() }}>
            <div className={styles.connectWalletButtonChild} />
            <b className={styles.connectWallet}>Cancel</b>
        </button>
    )
}

export default ButtonCancel;
