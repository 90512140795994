import React, { useState } from 'react';
import { ContentState, EditorState, convertFromHTML} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';


const DescriptionEditor = ({ initialValue="<p></p>", handleHTMLContent}) => {

  const getInitialState = () => {
    const blocksFromHTML = convertFromHTML(initialValue);
    return ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap,
    );
  }

  const [ editorState, setEditorState ] = useState(() => {
    handleHTMLContent(initialValue);
    return EditorState.createWithContent(getInitialState());
  });

  const onChange = (rawDraftContentState) => {
    const currentHTMLContent = String(draftToHtml(rawDraftContentState));
    handleHTMLContent(currentHTMLContent);
  }

  return (
    <div style={{ maxHeight: '190px', overflowY: 'auto' }}>
        <Editor
          editorState={ editorState }
          onEditorStateChange={ setEditorState }
          placeholder='Description'
          onChange={ (rawDraftContentState) => onChange(rawDraftContentState) }/>
    </div>
  );
}

export default DescriptionEditor;