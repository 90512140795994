import {
    Tooltip,
  } from 'react-tippy';

const Transaction = ({transaction, explorer}) => {
    const copyText = async() => {
        await navigator.clipboard.writeText(transaction);
    }
    return (
        <Tooltip
        title="Transaction copied!"
        position="bottom"
        trigger="click">
        <h4 onClick={ () => copyText() }>
            View your transaction in blockchain explorer&nbsp;
            <a href={ explorer+'/tx/'+transaction} target={'_blank'} rel="noreferrer">here</a>
        </h4>
        </Tooltip>
    )
}

export default Transaction;