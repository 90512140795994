export const apps = [
    'artcrypted.art',
    'artcrypted.test',
    'lyrz.io',
    'lyrz.test',
    'persea9.test',
    'houseblocks.test',
    'houseblocks.com',
    'docena.art',
    'docena.test',
]

export const validateDomain = (domain) => {
    return apps.find(app => app.toUpperCase() === domain.toUpperCase())
}


export const getHeadersAuth = () => {
    return {headers:{
        'X-API-Key' : localStorage.getItem('jwt')
    }}
}