import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { useNavigate } from "react-router-dom";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { findABIOf, isAddress } from 'services/blockchain';
import { Tooltip } from 'react-tippy';
import PaymentsIcon from '@mui/icons-material/Payments';
import BallotIcon from '@mui/icons-material/Ballot';
import EditNoteIcon from '@mui/icons-material/EditNote';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import AddAccountToWhitelistModal from './AddAccountToWhitelistModal';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));



function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export default function ModalSettings({ collection, open, handleClose }) {
  const [ openWhitelistModal, setOpenWhitelistModal ] = useState(false);
  const navigate = useNavigate();

  const selectMintType = () => {
    let url = `/${collection.domain}/select-mint-type/${collection.key}/${collection.blockchain}`;
    navigate(url);
  }

  const analytics = () => {
    let url = `/${collection.domain}/analytics/${collection.key}/${collection.blockchain}`;
    navigate(url);
  }

  const mintCopies = () => {
    let url = `/${collection.domain}/mint-one/${collection.key}/5`;
    navigate(url);
  }

    const cardPayments = () => {
        let url = `/${collection.domain}/card-payments/${collection.key}`;
        navigate(url);
    }

    const edit = () => {
        let url = `/${collection.domain}/edit/${collection.key}`;
        navigate(url);
    }

    const copyText = async(text) => {
        await navigator.clipboard.writeText(text);
    }

  const handleAddCountToWhiteList = () => {
    handleClose();
    setOpenWhitelistModal(true);
  }

  return (
    <div>
      <BootstrapDialog
        //onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          More options
        </BootstrapDialogTitle>
        <DialogContent dividers>
        <Box sx={{ flexGrow: 1 , border:'1px solid', padding: '10px' }}>
            {!isAddress(collection.key) &&
                <Stack sx={{ width: '100%'}} spacing={2}>
                    <Alert severity="warning">This contract address is temporal until deploy!</Alert>
                </Stack>
            }
            <Tooltip
        title="Copied!"
        position="bottom"
        trigger="click">
                        <Stack direction="row" spacing={2} alignItems="center" style={{padding : '10px'}} onClick={ async() => { await copyText(collection.key.toLowerCase())}}>
                <Typography gutterBottom>
                    Contract: {collection.key}
                </Typography>
            </Stack>
        </Tooltip>
        <Tooltip
        title="Copied!"
        position="bottom"
        trigger="click">
                        <Stack direction="row" spacing={2} alignItems="center" style={{padding : '10px'}} onClick={ async() => { await copyText(JSON.stringify(findABIOf(5)))}}>
                <Avatar><ContentCopyIcon /></Avatar>
                <Typography gutterBottom>
                    Copy ABI
                </Typography>
            </Stack>
        </Tooltip>
        { collection.price && collection.price > 0 &&
        <Tooltip
        title="Copied!"
        position="bottom"
        trigger="click">
            <Stack direction="row" spacing={2} alignItems="center" style={{padding : '10px'}} onClick={ async() => { await copyText('payableMint')}}>
                <Avatar><ContentCopyIcon /></Avatar>
                <Typography gutterBottom>
                    Copy buy method
                </Typography>
            </Stack>
        </Tooltip>
        }
        <Tooltip
        title="Copied!"
        position="bottom"
        trigger="click">
            <Stack direction="row" spacing={2} alignItems="center" style={{padding : '10px'}} onClick={ async() => { await copyText('quantity')}}>
                <Avatar><ContentCopyIcon /></Avatar>
                <Typography gutterBottom>
                    Copy contract params
                </Typography>
            </Stack>
        </Tooltip>
        </Box>
          {collection &&
            <Stack direction="row" spacing={2} alignItems="center" style={{padding : '10px'}}>
                <Typography gutterBottom>
                    <Button onClick={() => {edit()}} variant="contained"  endIcon={<EditNoteIcon />}>
                        Edit
                    </Button>
                </Typography>
                <Typography gutterBottom>
                    <Button onClick={handleAddCountToWhiteList} variant="contained" color="secondary"  endIcon={<BallotIcon />}>
                        Add account to whitelist
                    </Button>
                </Typography>
                <Typography gutterBottom>
                    <Button  onClick={analytics}  variant="contained" color="warning" endIcon={<AnalyticsIcon />}>
                        Analytics
                    </Button>
                </Typography>
            </Stack>
          }

          {isAddress(collection.key) &&
            <Stack direction="row" spacing={2} alignItems="center" style={{padding : '10px'}}>
                <Typography gutterBottom>
                    <Button onClick={() => {cardPayments()}} variant="contained"  endIcon={<PaymentsIcon />}>
                        Payments
                    </Button>
                </Typography>
            </Stack>
          }
        </DialogContent>
        { collection.nfts === 0 &&
            <DialogActions>
                <Button onClick={() => {mintCopies()}}>
                    Mint one
                </Button>
                <Button autoFocus color="secondary" onClick={() => {selectMintType()}}>
                    Mint batch
                </Button>
            </DialogActions>
        }
      </BootstrapDialog>
      <AddAccountToWhitelistModal collection={collection} open={openWhitelistModal} setOpen={() => setOpenWhitelistModal(false)} />
    </div>
  );
}