import { Switch } from '@mui/material';
import FormError from 'components/FormError';
import { Field } from "formik";

const InputRadio = ({ setFieldValue, errors,touched,name, title, textAlign }) => {
    return (
        <div style={{width: '100%', textAlign: textAlign}}>
        <label htmlFor={name}  style={{width: '100%'}}>{title.replace(/^\w/, (c) => c.toUpperCase())}</label>
        <div  htmlFor={name} style={{width: '100%'}}>
            <Field
                id={name}
                name={name}
                error={(errors[name] && touched[name])}
                onChange={(e) => setFieldValue(name, e.target.checked)}
                as={Switch}
            />

        </div>
        <FormError errors = {errors} touched = {touched} name={name}/>
    </div>
    )
}

export default InputRadio;